import { codigosCodVenTotvs } from './../../../classes/Fazenda';
import { LayerArea } from './../../../classes/LayerArea';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Fazenda } from 'src/app/classes/Fazenda';
import { FazendaService } from 'src/app/services/fazenda.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';
import { Area } from 'src/app/classes/Area';
import { Gleba } from 'src/app/classes/Gleba';
import { AreaService } from 'src/app/services/area.service';
import { GlebaService } from 'src/app/services/gleba.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { Setor } from 'src/app/classes/Setor';
import { SetorService } from 'src/app/services/setor.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-fazenda',
  templateUrl: './fazenda.component.html',
  styleUrls: ['./fazenda.component.scss']
})
export class FazendaComponent implements OnInit {
  @ViewChild('AreaModal', { static: false })
  AreaModal: UiModalComponent;
  @ViewChild('GlebaModal', { static: false })
  GlebaModal: UiModalComponent;
  @ViewChild('SetorModal', { static: false })
  SetorModal: UiModalComponent;
  fazendas: Fazenda[];
  auxFazendas: Fazenda[];
  fazenda: Fazenda;
  id: number;
  inserindo: boolean = false;
  carregando: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  textoBuscaAreaIrrigado: string = "";
  textoBuscaAreaSequeiro: string = "";
  textoBuscaSetorIrrigado: string = "";
  textoBuscaSetorSequeiro: string = "";
  perfilLogado: number = 0;

  setorId: number = 0;
  area: Area = new Area();
  setor: Setor = new Setor();
  setoresAux: Setor[];
  setoresIrrigado: Setor[];
  setoresSequeiro: Setor[];
  areasIrrigado: Area[];
  areasSequeiro: Area[];
  areasSetor: Area[];
  areasSemSetor: Area[];
  areasSemSetorIrrigado: Area[];
  areasSemSetorSequeiro: Area[];
  auxAreas: Area[];
  gleba: Gleba = new Gleba();
  glebas: Gleba[];
  gbAreaId: number = 0;
  gbNomeArea: string = "";
  layersArea: LayerArea[];
  mostraMapa: boolean = false;
  integraTotvs: boolean = false;
  integraSankhya: boolean = false;
  codsCodVenTotvs = codigosCodVenTotvs;
  editandoMapa: boolean = false;
  organizaPorSetor: boolean;

  setorSelecionadoId = null;

  // tradução
  SETOR_DELETADO: string = 'Setor deletado!';
  ERRO: string = 'Erro!';
  FEITO: string = 'Feito!';
  SIM: string = 'Sim';
  CANCELAR: string = 'Cancelar';
  SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA: string = 'Se deletar, esta ação não poderá ser desfeita!';
  SETOR_CADASTRADO: string = 'Setor cadastrado!';
  SETOR_ALTERADO: string = 'Setor alterado';
  GLEBA_DELETADA: string = 'Gleba deletada!';
  GLEBA_CADASTRADA: string = 'Gleba cadastrada!';
  GLEBA_ALTERADA: string = 'Gleba alterada!';
  NENHUMA_AREA_INFORMADA: string = 'Nenhuma área informada';
  AREA_DELETADA: string = 'Área deletada';
  AREA_CADASTRADA: string = 'Área cadastrada!';
  AREA_ALTERADA: string = 'Área alterada!';
  FAZENDA_DELETADA: string = 'Fazenda deletada!';
  FAZENDA_CADASTRADA: string = 'Fazenda cadastrada!';
  FAZENDA_ALTERADA: string = 'Fazenda Alterada!';

  constructor(
    private utils: UtilsService,
    private fazendaService: FazendaService,
    private areaService: AreaService,
    private glebaService: GlebaService,
    private setorService: SetorService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private translate: TranslateService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.integraTotvs = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Totvs_RM;
    this.integraSankhya = this.storageService.getEmpresa().ErpIntegrado == enumErpIntegrado.Sankhya;
    this.fazenda = new Fazenda();

    if (this.id > 0)
      this.selecionarFazenda();
    else
      this.listarFazendas();
  }

  selecionarFazenda() {
    this.configurarTraducao();
    this.carregando = true;
    this.fazendaService.getFazenda(this.id).subscribe(res => {
      this.fazenda = new Fazenda();
      this.fazenda = res;
      this.carregando = false;
      this.organizaPorSetor = this.storageService.getEmpresa().UsaSetores;
      this.listarSetores();
      this.selecionarAreas();
      this.listarAreas(this.fazenda.Id);
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarFazendas() {
    this.configurarTraducao();
    this.carregando = true;
    this.fazendaService.getFazendas().subscribe(
      res => {
        this.fazendas = res;
        this.auxFazendas = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarFazendas();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.configurarTraducao();

    if (this.fazenda.Id > 0)
      this.fazendaService.putFazenda(this.fazenda.Id, this.fazenda).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.FAZENDA_ALTERADA, 'success');
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.fazendaService.postFazenda(this.fazenda).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.FAZENDA_CADASTRADA, 'success');
          this.carregando = false;
          this.router.navigate(['/cadastros/fazenda']);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(fazendaId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.fazendaService.deleteFazenda(fazendaId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.FAZENDA_DELETADA, 'success');
            this.listarFazendas();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  filtrarFazendas() {
    this.fazendas = this.auxFazendas.filter(x =>
      x.Nome.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca)
    );
  }

  cancelar() {
    this.router.navigate(['/cadastros/fazenda']);
  }


  //AREAS

  inserirArea() {
    this.area = new Area();
    this.layersArea = [];
    this.mostraMapa = true;
    this.AreaModal.show();

  }

  alteraLayers(layers: string) {
    this.area.LayersArea = layers;
    this.layersArea = JSON.parse(layers);
  }

  saveArea(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.configurarTraducao();
    this.area.FazendaId = this.fazenda.Id;

    if (this.area.Id > 0)
      this.areaService.putArea(this.area.Id, this.area).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.AREA_ALTERADA, 'success');
          this.carregando = false;
          this.mostraMapa = false;
          this.AreaModal.hide();
          this.selecionarFazenda();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.areaService.postArea(this.area).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.AREA_CADASTRADA, 'success');
          this.carregando = false;
          this.mostraMapa = false;
          this.AreaModal.hide();
          this.selecionarFazenda();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  selecionarArea(idArea: number) {
    this.carregando = true;
    this.configurarTraducao();
    this.areaService.getArea(idArea).subscribe(res => {
      this.area = new Area();
      this.area = res;
      this.layersArea = JSON.parse(this.area.LayersArea);
      this.carregando = false;
      this.mostraMapa = true;
      this.AreaModal.show();
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarAreas(idFazenda: number) {
    this.carregando = true;
    this.configurarTraducao();
    this.areaService.getAreasPorFazenda(idFazenda).subscribe(
      res => {
        this.auxAreas = res;
        this.carregando = false;

        this.filtrarAreasIrrigado();
        this.filtrarAreasSequeiro();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  listarAreasSetor() {
    this.carregando = true;
    this.configurarTraducao();
    this.areaService.getAreasPorFazenda(this.fazenda.Id).subscribe(
      res => {
        this.auxAreas = res;
        this.carregando = false;

        this.filtrarSetor();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  filtrarSetor() {
    let setor = this.setoresAux.find(x => this.setorSelecionadoId == x.Id);

    var txt = '';
    if (setor && setor.Irrigado)
      txt = this.textoBuscaSetorIrrigado;
    else
      txt = this.textoBuscaSetorSequeiro;

    this.areasSetor = this.auxAreas.filter(x =>
      x.SetorId == this.setorSelecionadoId && (x.Descricao.toUpperCase().includes(txt.toUpperCase()) ||
        x.Id.toString().includes(txt)));

    this.areasSetor.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
  }

  filtrarAreasIrrigado() {
    this.areasIrrigado = this.auxAreas.filter(x =>
      x.Irrigado &&
      (x.Descricao.toUpperCase().includes(this.textoBuscaAreaIrrigado.toUpperCase()) ||
        x.Id.toString().includes(this.textoBuscaAreaIrrigado))
    );

    this.areasIrrigado.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
  }

  filtrarAreasSequeiro() {
    this.areasSequeiro = this.auxAreas.filter(x =>
      !x.Irrigado &&
      (x.Descricao.toUpperCase().includes(this.textoBuscaAreaSequeiro.toUpperCase()) ||
        x.Id.toString().includes(this.textoBuscaAreaSequeiro))
    );

    this.areasSequeiro.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
  }

  filtrarSemSetorIrrigado() {
    this.areasSemSetorIrrigado = this.areasSemSetor.filter(x =>
      x.Irrigado &&
      (x.Descricao.toUpperCase().includes(this.textoBuscaAreaSequeiro.toUpperCase()) ||
        x.Id.toString().includes(this.textoBuscaAreaSequeiro))
    );

    this.areasSequeiro.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
  }

  filtrarSemSetorSequeiro() {
    this.areasSemSetorSequeiro = this.areasSemSetor.filter(x =>
      !x.Irrigado &&
      (x.Descricao.toUpperCase().includes(this.textoBuscaAreaSequeiro.toUpperCase()) ||
        x.Id.toString().includes(this.textoBuscaAreaSequeiro))
    );

    this.areasSequeiro.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
  }

  excluirArea(areaId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.areaService.deleteArea(areaId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.AREA_DELETADA, 'success');
            this.listarAreas(this.fazenda.Id);
            this.selecionarAreas();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  //GLEBAS

  inserirGleba() {
    this.gleba = new Gleba();
    this.GlebaModal.show();
  }

  selecionarGleba(idGleba: number) {
    this.configurarTraducao();
    this.carregando = true;
    this.glebaService.getGleba(idGleba).subscribe(res => {
      this.gleba = new Gleba();
      this.gleba = res;
      this.carregando = false;
      this.GlebaModal.show();
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  listarGlebas(idArea: number, descricao?: string) {
    this.configurarTraducao();
    this.gbAreaId = idArea;
    if (descricao)
      this.gbNomeArea = descricao;
    this.carregando = true;
    this.glebaService.getGlebasPorArea(idArea).subscribe(
      res => {
        this.glebas = res;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  limparGleba() {
    this.gbAreaId = 0;
    this.gbNomeArea = "";
  }

  saveGleba(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    if (this.gbAreaId <= 0) {
      Swal.fire(this.ERRO, this.NENHUMA_AREA_INFORMADA, 'error');
      this.carregando = false;
      return;
    }
    this.carregando = true;
    this.gleba.AreaId = this.gbAreaId;

    if (this.gleba.Id > 0)
      this.glebaService.putGleba(this.gleba.Id, this.gleba).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.GLEBA_ALTERADA, 'success');
          this.carregando = false;
          this.GlebaModal.hide();
          this.listarGlebas(this.gbAreaId);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.glebaService.postGleba(this.gleba).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.GLEBA_CADASTRADA, 'success');
          this.carregando = false;
          this.GlebaModal.hide();
          this.listarGlebas(this.gbAreaId);
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  checkBoxChange(opt: string) {
    switch (opt) {
      case 'Irrigado':
        this.area.Irrigado = true;
        this.area.Proprio = false;
        break;
      case 'Proprio':
        this.area.Proprio = true;
        this.area.Irrigado = false;
    }
  }

  excluirGleba(glebaId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.glebaService.deleteGleba(glebaId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.GLEBA_DELETADA, 'success');
            this.listarGlebas(this.gbAreaId);
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  //Setores

  inserirSetor() {
    this.setor = new Setor();
    this.SetorModal.show();
  }

  listarSetores() {
    this.setorService.getSetoresPorFazenda(this.id).subscribe(ret => {
      this.setoresAux = ret;

      this.setoresIrrigado = this.setoresAux.filter(x => x.Irrigado);
      this.setoresSequeiro = this.setoresAux.filter(x => !x.Irrigado);
    });
  }

  selecionarSetor(idSetor: number) {
    this.configurarTraducao();
    this.carregando = true;
    this.setorService.getSetor(idSetor).subscribe(res => {
      this.setor = new Setor();
      this.setor = res;
      this.carregando = false;
      this.SetorModal.show();
    },
      ex => {
        Swal.fire(this.ERRO, ex.error.ExceptionMessage, 'error');
        this.carregando = false;
      })
  }

  saveSetor(form: any) {
    this.configurarTraducao();
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.setor.FazendaId = this.fazenda.Id;

    this.carregando = true;

    if (this.setor.Id > 0)
      this.setorService.putSetor(this.setor.Id, this.setor).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.SETOR_ALTERADO, 'success');
          this.carregando = false;
          this.SetorModal.hide();
          this.listarSetores();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.setorService.postSetor(this.setor).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.SETOR_CADASTRADO, 'success');
          this.carregando = false;
          this.SetorModal.hide();
          this.listarSetores();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
  }

  excluirSetor(setorId: number, nome: string) {
    this.configurarTraducao();
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.setorService.deleteSetor(setorId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.SETOR_DELETADO, 'success');
            this.listarSetores();
            this.carregando = false;
          },
          err => {
            Swal.fire(this.ERRO, err.message, 'error');
            this.carregando = false;
          });
      }
    });
  }

  selecionarSafra(item: number) {
    if (this.setorSelecionadoId === item) {
      this.setorSelecionadoId = 0;
    } else {
      this.setorSelecionadoId = item;
      this.listarAreasSetor();
    }
  }

  selecionarAreas() {
    this.configurarTraducao();
    this.setorSelecionadoId = 0;

    this.carregando = true;
    this.areaService.getAreasPorFazenda(this.fazenda.Id).subscribe(
      res => {
        this.auxAreas = res;
        this.carregando = false;

        this.areasSemSetor = this.auxAreas.filter(x =>
          !x.SetorId);

        this.areasSemSetorIrrigado = this.areasSemSetor.filter(x => x.Irrigado);
        this.areasSemSetorSequeiro = this.areasSemSetor.filter(x => !x.Irrigado);

        if (this.areasSetor != null)
          this.areasSetor.sort((a, b) => a.Descricao.localeCompare(b.Descricao));
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  configurarTraducao() {
    this.SETOR_DELETADO = this.translate.instant('SETOR_DELETADO');
    this.ERRO = this.translate.instant('ERRO');
    this.FEITO = this.translate.instant('FEITO');
    this.SIM = this.translate.instant('SIM');
    this.CANCELAR = this.translate.instant('CANCELAR');
    this.SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA = this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA');
    this.SETOR_CADASTRADO = this.translate.instant('SETOR_CADASTRADO');
    this.SETOR_ALTERADO = this.translate.instant('SETOR_ALTERADO');
    this.GLEBA_DELETADA = this.translate.instant('GLEBA_DELETADA');
    this.GLEBA_CADASTRADA = this.translate.instant('GLEBA_CADASTRADA');
    this.GLEBA_ALTERADA = this.translate.instant('GLEBA_ALTERADA');
    this.NENHUMA_AREA_INFORMADA = this.translate.instant('NENHUMA_AREA_INFORMADA');
    this.AREA_DELETADA = this.translate.instant('AREA_DELETADA');
    this.AREA_CADASTRADA = this.translate.instant('AREA_CADASTRADA');
    this.AREA_ALTERADA = this.translate.instant('AREA_ALTERADA');
    this.FAZENDA_DELETADA = this.translate.instant('FAZENDA_DELETADA');
    this.FAZENDA_CADASTRADA = this.translate.instant('FAZENDA_CADASTRADA');
    this.FAZENDA_ALTERADA = this.translate.instant('FAZENDA_ALTERADA');
  }

}
