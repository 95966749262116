import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Atividade } from 'src/app/classes/Atividade';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { AtividadeService } from 'src/app/services/atividade.service';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';

@Component({
  selector: 'app-select-atividade',
  templateUrl: './select-atividade.component.html',
  styleUrls: ['./select-atividade.component.scss']
})
export class SelectAtividadeComponent implements OnInit {

  @Input() isSubmit: boolean;
  @Input() atividadeId: number = 0;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() auxClass: string;
  @Input() ocultarMsgObrigatorio: boolean = false;
  @Output() atividadeChange: EventEmitter<number> = new EventEmitter();
  @Input() atividades: Atividade[];

  constructor(private atividadeService: AtividadeService, private grupoOperacaoService: GrupoOperacaoService) { }

  ngOnInit() {
    this.atividadeService.getAtividades().subscribe(ret =>{
      let atividades : Atividade[] = ret;

      this.grupoOperacaoService.getGruposOperacao().subscribe(ret =>{
        let gruposOperacao : GrupoOperacao[] = ret;

        atividades.forEach(a => {
          let grupo = gruposOperacao.filter(g => g.Id == a.GrupoOperacaoId);

          a.GrupoOperacaoDescricao = grupo.length > 0 ? grupo[0].Descricao : '';
        });

        this.atividades = atividades;

      });
    });
  }

  filtrarAtividade(term: string, item: Atividade) {
    term = term.toLowerCase();
    return item.Descricao.toLowerCase().indexOf(term) > -1 || (item.GrupoOperacaoDescricao ? item.GrupoOperacaoDescricao.toLowerCase().indexOf(term) > -1 : false);
  }

  onChange(){
    this.atividadeChange.emit(this.atividadeId);
  }


}
