export class ImprodutividadeEquipamento {
    Id: number;
    EquipamentoApontamentoId: number;
    TipoImprodutividadeId: number;
    Motivo:string = "";
    HoraInicio:string = "";
    HoraFim:string = "";
    FlagExcluido: boolean;
    HorimetroInicial: number = 0;
    HorimetroFinal: number = 0;
    Ligado:boolean = false;
    Novo: boolean = false;
}
