import { ProdutoService } from 'src/app/services/produto.service';
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service';
import { GrupoProduto } from 'src/app/classes/GrupoProduto';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Etapa } from 'src/app/classes/Etapa';
import { Planejamento } from 'src/app/classes/Planejamento';
import { AtividadePlanejamento } from 'src/app/classes/AtividadePlanejamento';
import { Atividade } from 'src/app/classes/Atividade';
import { EstadioFenologico } from 'src/app/classes/EstadioFenologico';
import { AtividadePlanejamentoService } from 'src/app/services/atividade-planejamento.service';
import { TipoAplicacao } from 'src/app/classes/TipoAplicacao';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { Produto } from 'src/app/classes/Produto';
import { ItensAtividade } from 'src/app/classes/ItensAtividade';
import { ItensAtividadeService } from 'src/app/services/itens-atividade.service';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/utils/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';

import { EmpresaService } from 'src/app/services/empresa.service';

import { auxIntegracaoService } from 'src/app/services/auxIntegracaoService';
import { auxPossuiIntegracao } from 'src/app/classes/auxiliar/auxPossuiIntegracao';

@Component({
  selector: 'app-lista-detalhe',
  templateUrl: './lista-detalhe.component.html',
  styleUrls: ['./lista-detalhe.component.scss']
})
export class ListaDetalheComponent implements OnInit, OnChanges {

  @Input() etapa: Etapa;
  @Input() planejamento: Planejamento;
  @Input() atividades: Atividade[];
  @Input() gruposOperacao: GrupoOperacao[];
  @Input() estadiosFenologicosCadastrados: EstadioFenologico[];
  @Input() tiposAplicacoesCadastradas: TipoAplicacao[];
  @Input() atividadesPlanejamentoSalvas: AtividadePlanejamento[];
  @Input() itensAtividadesSalvos: ItensAtividade[];
  @Input() produtos: Produto[];
  dicionarioProdutos: any;
  @Input() sementes: Produto[];
  @Input() servicos: Produto[];
  @Input() visualizacaoAgrupada: boolean;
  @Input() ultimaLista: boolean;
  @Input() processosPlanejamento: ProcessoPlanejamento[];
  @Output() attListEtapas: EventEmitter<any> = new EventEmitter();

  @Output() custoTotalEtapa: EventEmitter<number> = new EventEmitter();
  @Output() custoTotalPlanejamento: EventEmitter<number> = new EventEmitter();
  @Output() custoProcessoPlanejamento: EventEmitter<number> = new EventEmitter();

  estadiosFenologicos: EstadioFenologico[];
  tiposAplicacoes: TipoAplicacao[];
  atividadesPlanejamento: AtividadePlanejamento[];
  itensAtividades: ItensAtividade[];
  plantasPorMetro: number = 0;
  gruposProdutos: GrupoProduto[];

  carregando: boolean = false;
  PermiteVariasAtividadesPorEtapa: boolean = false;
  listaAtividades = new Map<number, Atividade[]>();

  auxPossuiIntegracao: auxPossuiIntegracao = new auxPossuiIntegracao();

  //ng-select produto
  pagina: number = 2;
  termoBusca: String = '';
  carregandoProduto: boolean = false;
  ultimaPagina: boolean = false;
  public inputProduto$ = new Subject<string | null>();
  public inputSemente$ = new Subject<string | null>();
  public inputServico$ = new Subject<string | null>();

  public isPlanoMestre: boolean = false;

  precisaoDose: number = 3;

  constructor(private atividadePlanejamentoService: AtividadePlanejamentoService,
    private itensAtividadeService: ItensAtividadeService,
    private grupoProdutoService: GrupoProdutoService,
    private produtoService: ProdutoService,
    private empresaService: EmpresaService,
    private toasty: CustomToastyService,
    private utils: UtilsService,
    private integracaoService: auxIntegracaoService,
    private storageService: StorageService) {
    this.inputProduto$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMore(newTerm);
    });

    this.inputSemente$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMoreSemente(newTerm);
    });

    this.inputServico$.subscribe((newTerm) => {
      this.pagina = 1;
      this.fetchMoreServico(newTerm);
    });
  }

  ngOnInit() {
    this.integracaoService.getIntegracao().then(res => {
      this.auxPossuiIntegracao = res;
    });

    this.grupoProdutoService.getGruposProduto().subscribe(ret => {
      this.gruposProdutos = ret;
      if (this.produtos) {
        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
      }

      if (this.sementes)
        this.sementes.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);

      if (this.servicos)
        this.servicos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);

      /*if(this.etapa.ProcessoPlanejamentoId == 9) {
        this.atividades = this.atividades.filter(x => !x.UtilizaInsumo).sort((a, b) => a.Descricao > b.Descricao ? 1 : -1);
      }*/

      if (this.getProcessoPlanejamento(this.etapa.ProcessoPlanejamentoId) && this.getProcessoPlanejamento(this.etapa.ProcessoPlanejamentoId).Plantio) {
        this.atividades = this.atividades.filter(x => x.Plantio).sort((a, b) => a.Descricao > b.Descricao ? 1 : -1);
      }

      this.atividades = this.atividades.sort((a, b) => a.Descricao > b.Descricao ? 1 : -1);
    });


    const empresa = this.storageService.getEmpresa();
    this.PermiteVariasAtividadesPorEtapa = empresa.PermiteVariasAtividadesPorEtapa;

    if (this.produtos && this.produtos != undefined && this.produtos.length > 0) {
      this.montaDicionarioProdutos();
    }
    else {
      this.produtoService
          .getProdutosPaginandoSemCultivar((this.planejamento.Id ? this.planejamento.Id : 0), 1, '', false, false)
          .subscribe(ret => {
            this.produtos = ret.Lista;
            this.montaDicionarioProdutos();
          });
    }

    this.empresaService.getEmpresa(empresa.Id).subscribe(res => {
      this.precisaoDose = res.CasasDecimaisDose;
    });

    this.loadDados();
    this.changeAtividade(0);
  }

  adicionarERP()
  {
    if(this.auxPossuiIntegracao.possuiIntegracao){
      this.produtos.forEach(prod =>{
        if (!prod.Descricao.includes('IDERP:')) {
          prod.Descricao = prod.Descricao + ' - IDERP: ' + prod.IdERP;
        }
      });
      this.sementes.forEach(prod =>{
        if (!prod.Descricao.includes('IDERP:')) {
          prod.Descricao = prod.Descricao + ' - IDERP: ' + prod.IdERP;
        }
      });
      this.servicos.forEach(prod =>{
        if (!prod.Descricao.includes('IDERP:')) {
          prod.Descricao = prod.Descricao + ' - IDERP: ' + prod.IdERP;
        }
      });
    }
  }

  montaDicionarioProdutos() {
    this.dicionarioProdutos = this.produtos.reduce((acc, obj) => {
      acc[obj.Id] = obj;
      return acc;
    }, {});
  }

  ngOnChanges() {
    this.loadDados();
  }

  loadDados() {
    this.isPlanoMestre = this.planejamento != null && this.planejamento.Padrao;

    this.tiposAplicacoes = []
    let tipoApl = new TipoAplicacao();
    tipoApl.Id = -1;
    tipoApl.Descricao = "-";
    this.tiposAplicacoes.push(tipoApl);
    if (this.tiposAplicacoesCadastradas)
      this.tiposAplicacoesCadastradas.forEach(tipo => {
        this.tiposAplicacoes.push(tipo);
      });

    if (this.itensAtividadesSalvos && this.etapa != null)
      this.itensAtividades = this.itensAtividadesSalvos.filter(x => x.Id > 0);

    if (this.atividadesPlanejamentoSalvas && this.etapa != null) {
      this.atividadesPlanejamento = this.atividadesPlanejamentoSalvas.filter(x => x.EtapaId == this.etapa.Id && x.DiasAposPlantio == this.etapa.DiasAposPlantio);

      let i: number = 0;
      this.atividadesPlanejamento.forEach(x => {
        if (x.TipoAplicacaoId == null)
          x.TipoAplicacaoId = -1;

        //Alimenta atividades para filtro
        let atividadeSelecionada = this.atividades.find(a => a.Id == x.AtividadeId);
        if (!atividadeSelecionada.Padrao) {
          this.listaAtividades.set(i, this.atividades.filter(a => a.GrupoOperacaoId == atividadeSelecionada.GrupoOperacaoId));
          x.GrupoOperacaoId = atividadeSelecionada.GrupoOperacaoId;
        } else
          this.listaAtividades.set(i, this.atividades);
        i++;
      });

      if (this.atividadesPlanejamentoSalvas.length > 0) {
        this.custoEtapa(this.atividadesPlanejamento[0], true);
        this.custoPlanejamento();
        this.custoProcesso();
      }
      this.adicionarERP();
    }

    //this.novaAtividade();
  }

  novaAtividade() {
    if (!this.etapa)
      return;

    if (!this.atividadesPlanejamento)
      this.atividadesPlanejamento = [];

    if (this.atividadesPlanejamento.length == 0 || this.atividadesPlanejamento[this.atividadesPlanejamento.length - 1].Id > 0) {
      let nova = new AtividadePlanejamento();
      nova.EtapaId = this.etapa.Id;
      nova.PlanejamentoId = this.planejamento.Id;
      nova.DiasAposPlantio = this.etapa.DiasAposPlantio;
      nova.EstadioFenologicoId = this.etapa.EstadioFenologico.Id == null || this.etapa.EstadioFenologico.Id == -1 ? null : this.etapa.EstadioFenologico.Id;
      nova.AtividadeId = 0;
      nova.TipoAplicacaoId = 0;
      nova.ProcessoPlanejamentoId = this.etapa.ProcessoPlanejamentoId;
      nova.DataPrevista = new Date(this.planejamento.DataPrevistaPlantio);
      if (nova.DataPrevista) {
        nova.DataPrevista.setDate(nova.DataPrevista.getDate() + this.etapa.DiasAposPlantio);
      }
      this.atividadesPlanejamento.push(nova);
    }
  }

  changeGrupoOperacao(index: number, grupoOperacaoId: number) {
    this.listaAtividades.set(index, this.atividades.filter(x => x.GrupoOperacaoId == grupoOperacaoId));
  }

  changeAtividade(index: number, zerarDataFinal?: boolean) {
    let atividadePlanejamento = this.atividadesPlanejamento[index];

    if (atividadePlanejamento.SementeMt == null)
      atividadePlanejamento.SementeMt = 0;
    if (atividadePlanejamento.Espacamento == null)
      atividadePlanejamento.Espacamento = 0;

    if (atividadePlanejamento && this.getProcessoPlanejamento(atividadePlanejamento.ProcessoPlanejamentoId)
      && this.getProcessoPlanejamento(atividadePlanejamento.ProcessoPlanejamentoId).Plantio)
      this.plantasPorMetro = (10000 / (atividadePlanejamento.Espacamento / 100)) * atividadePlanejamento.SementeMt;

    if (atividadePlanejamento.AtividadeId > 0 &&
      (!this.getAtividade(atividadePlanejamento.AtividadeId).InformarTipoAplicacao || atividadePlanejamento.TipoAplicacaoId != 0)) {
      this.carregando = true;

      if (!this.getAtividade(atividadePlanejamento.AtividadeId).InformarTipoAplicacao || atividadePlanejamento.TipoAplicacaoId == -1)
        atividadePlanejamento.TipoAplicacaoId = null;

      if (zerarDataFinal)
        atividadePlanejamento.DataPrevistaFinal = null;

      if (atividadePlanejamento.TemPulverizacaoSulco) {
        if (!this.itensAtividades || !this.itensAtividades.find(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.PulverizacaoSulco)) {
          this.novoItemAtividade(atividadePlanejamento.Id, true, false, false);
        }
      }
      else {
        if (this.itensAtividades && this.itensAtividades.find(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.PulverizacaoSulco)) {
          this.itensAtividades.filter(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.PulverizacaoSulco).forEach(x => {
            this.itensAtividades.splice(this.itensAtividades.indexOf(x), 1);
          });
        }
      }


      if (atividadePlanejamento.TemAdubacaoLinha) {
        if (!this.itensAtividades || !this.itensAtividades.find(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.AdubacaoLinha)) {
          this.novoItemAtividade(atividadePlanejamento.Id, false, true, false);
        }
      }
      else {
        if (this.itensAtividades && this.itensAtividades.find(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.AdubacaoLinha)) {
          this.itensAtividades.filter(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.AdubacaoLinha).forEach(x => {
            this.itensAtividades.splice(this.itensAtividades.indexOf(x), 1);
          });
        }
      }


      if (atividadePlanejamento.TemTratamentoSementes) {
        if (!this.itensAtividades || !this.itensAtividades.find(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.TratamentoSementes)) {
          this.novoItemAtividade(atividadePlanejamento.Id, false, false, true);
        }
      }
      else {
        if (this.itensAtividades && this.itensAtividades.find(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.TratamentoSementes)) {
          this.itensAtividades.filter(x => x.AtividadePlanejamentoId == atividadePlanejamento.Id && x.TratamentoSementes).forEach(x => {
            this.itensAtividades.splice(this.itensAtividades.indexOf(x), 1);
          });
        }
      }

      if (atividadePlanejamento.Id > 0)
        this.atividadePlanejamentoService.putAtividadePlanejamento(atividadePlanejamento.Id, atividadePlanejamento).subscribe(
          res => {
            if (!this.isPlanoMestre) {
              this.atividadePlanejamentoService.getAtividadePlanejamento(atividadePlanejamento.Id).subscribe(res => {
                atividadePlanejamento = res;

                if (atividadePlanejamento.TipoAplicacaoId == null)
                  atividadePlanejamento.TipoAplicacaoId = -1;

                this.atividadesPlanejamento[index] = atividadePlanejamento;
                this.carregando = false;
              });
            } else {
              this.carregando = false;
            }
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      else
        this.atividadePlanejamentoService.postAtividadePlanejamento(atividadePlanejamento).subscribe(
          res => {
            atividadePlanejamento = res;
            if (!atividadePlanejamento.TipoAplicacaoId)
              atividadePlanejamento.TipoAplicacaoId = -1;
            this.atividadesPlanejamento[index] = atividadePlanejamento;

            if (!this.atividadesPlanejamentoSalvas)
              this.atividadesPlanejamentoSalvas = [];

            this.atividadesPlanejamentoSalvas.push(atividadePlanejamento);
            this.carregando = false;
            //this.novaAtividade();
            //this.novoItemAtividade(atividadePlanejamento.Id);
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
    }
  }

  changeDataPrevista(index: number, dataPrevista: Date, final?: boolean) {
    let atividadePlanejamento = this.atividadesPlanejamento[index];
    atividadePlanejamento.DataPrevistaFinal = dataPrevista;
    atividadePlanejamento.DataPrevista = dataPrevista;

    this.changeAtividade(index, !final);
  }

  excluir(id: number, nome: string, index: number) {
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.atividadePlanejamentoService.deleteAtividadePlanejamento(id).subscribe(
          res => {
            this.toasty.show('Feito!', 'Atividade deletada!', 'success');
            this.atividadesPlanejamento.splice(index, 1);
            this.attListEtapas.emit();
            this.carregando = false;
          },
          err => {
            this.attListEtapas.emit();
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  getAtividade(id): Atividade {
    let atividades = this.atividades.filter(x =>
      x.Id == id
    );

    return atividades.length > 0 ? atividades[0] : new Atividade();
  }

  novoItemAtividade(atividadePlanejamentoId: number, pulverizacaoSulco: boolean, adubacaoLinha: boolean, tratamentoSementes) {
    if (!this.etapa)
      return;

    if (!this.itensAtividades)
      this.itensAtividades = [];

    let itensDetalhe = this.getItensPorAtividade(atividadePlanejamentoId);
    if (itensDetalhe.length == 0 || itensDetalhe[itensDetalhe.length - 1].Id > 0) {
      let novo = new ItensAtividade();
      novo.Ordem = itensDetalhe.length + 1;
      novo.AtividadePlanejamentoId = atividadePlanejamentoId;
      novo.ProdutoId = 0;
      novo.PrecoUnitario = 0;
      novo.PulverizacaoSulco = pulverizacaoSulco;
      novo.AdubacaoLinha = adubacaoLinha;
      novo.TratamentoSementes = tratamentoSementes;
      novo.IsServico = false;
      this.itensAtividades.push(novo);
    }
    else {
      this.toasty.show('Atenção!', 'Verifique Quantidade, Dose, ou valor do item anterior antes de adicionar um novo!', 'warning');
    }
  }

  novoServicoAtividade(atividadePlanejamentoId: number) {
    if (!this.etapa)
      return;

    if (!this.itensAtividades)
      this.itensAtividades = [];

    let itensDetalhe = this.getServicosPorAtividade(atividadePlanejamentoId);
    if (itensDetalhe.length == 0 || itensDetalhe[itensDetalhe.length - 1].Id > 0) {
      let novo = new ItensAtividade();
      novo.Ordem = itensDetalhe.length + 1;
      novo.AtividadePlanejamentoId = atividadePlanejamentoId;
      novo.ProdutoId = 0;
      novo.PrecoUnitario = 0;
      novo.PulverizacaoSulco = false;
      novo.AdubacaoLinha = false;
      novo.IsServico = true;
      this.itensAtividades.push(novo);
    }
    else {
      this.toasty.show('Atenção!', 'Verifique Quantidade ou valor do serviço anterior antes de adicionar um novo!', 'warning');
    }
  }

  getItensPorAtividade(atividadePlanejamentoId: number): ItensAtividade[] {
    if (!this.itensAtividades)
      return [];

    let retorno = this.itensAtividades.filter(x =>
      x.AtividadePlanejamentoId == atividadePlanejamentoId && !x.IsServico &&
      (((x.PulverizacaoSulco == null || !x.PulverizacaoSulco)) &&
        (x.AdubacaoLinha == null || !x.AdubacaoLinha) &&
        (x.TratamentoSementes == null || !x.TratamentoSementes))
    ).sort((a, b) => a.Ordem - b.Ordem);

    return retorno;
  }

  getServicosPorAtividade(atividadePlanejamentoId: number): ItensAtividade[] {
    if (!this.itensAtividades)
      return [];

    return this.itensAtividades.filter(x =>
      x.AtividadePlanejamentoId == atividadePlanejamentoId && x.IsServico
    ).sort((a, b) => a.Ordem - b.Ordem);
  }

  getItensPorAtividadePulverizacaoSulco(atividadePlanejamentoId: number): ItensAtividade[] {
    if (!this.itensAtividades)
      return [];

    return this.itensAtividades.filter(x => x.AtividadePlanejamentoId == atividadePlanejamentoId
      && x.PulverizacaoSulco != null && x.PulverizacaoSulco).sort((a, b) => a.Ordem - b.Ordem);
  }

  getItensPorAtividadeAdubacaoLinha(atividadePlanejamentoId: number): ItensAtividade[] {
    if (!this.itensAtividades)
      return [];

    return this.itensAtividades.filter(x =>
      x.AtividadePlanejamentoId == atividadePlanejamentoId && x.AdubacaoLinha != null && x.AdubacaoLinha
    ).sort((a, b) => a.Ordem - b.Ordem);
  }

  getItensPorAtividadeTratamentoSementes(atividadePlanejamentoId: number): ItensAtividade[] {
    if (!this.itensAtividades)
      return [];

    return this.itensAtividades.filter(x =>
      x.AtividadePlanejamentoId == atividadePlanejamentoId && x.TratamentoSementes != null && x.TratamentoSementes
    ).sort((a, b) => a.Ordem - b.Ordem);
  }

  getProduto(id): Produto {
    if (!id || id == 0)
      return new Produto();
    if (!this.produtos) {
      return this.buscarProduto(id);
    }
    else {
      let produtos = this.produtos.filter(x =>
        x.Id == id
      );
      if (produtos.length > 0){
          this.adicionarERP();
          return produtos[0];
        }
      else
        return this.buscarProduto(id);
    }
  }

  getServico(id): Produto {
    if (!id || id == 0)
      return new Produto();
    if (!this.produtos) {
      return this.buscarProduto(id);
    }
    else {
      let produtos = this.servicos.filter(x =>
        x.Id == id
      );
      if (produtos.length > 0){
        this.adicionarERP();
        return produtos[0];
      }
      else
        return this.buscarProduto(id);
    }
  }

  buscarProduto(id: number): Produto {
    let novo = new Produto();
    novo.UnidadeMedida = 'UN';
    this.produtoService.getProduto(id).subscribe(
      res => {
        let novoProduto: Produto = res;
        if (!this.produtos)
          this.produtos = [];
        this.produtos.push(novoProduto);
        this.adicionarERP();
        return novoProduto;
      },
      err => {
        console.log("buscarProduto: ", err);
        return novo;
      });

    return;
  }

  getGrupoProduto(id): GrupoProduto {
    let grupos = this.gruposProdutos.filter(x =>
      x.Id == id
    );

    let novo = new GrupoProduto();

    return grupos.length > 0 ? grupos[0] : novo;
  }

  timoutProduto: any;
  changeProduto(atividadePlanejamentoId: number,
    index: number,
    pulverizacaoSulco: boolean,
    adubacaoLinha: boolean,
    tratamentoSementes: boolean,
    servico: boolean,
    naoMudarPreco?: boolean) {
    if (this.timoutProduto)
      clearTimeout(this.timoutProduto);

    this.timoutProduto = setTimeout(() => {
      this.termoBusca = '';
      this.pagina = 1;

      let listaItens = [];
      if (pulverizacaoSulco)
        listaItens = this.getItensPorAtividadePulverizacaoSulco(atividadePlanejamentoId);
      else if (adubacaoLinha)
        listaItens = this.getItensPorAtividadeAdubacaoLinha(atividadePlanejamentoId);
      else if (tratamentoSementes)
        listaItens = this.getItensPorAtividadeTratamentoSementes(atividadePlanejamentoId);
      else if (servico)
        listaItens = this.getServicosPorAtividade(atividadePlanejamentoId);
      else
        listaItens = this.getItensPorAtividade(atividadePlanejamentoId);

      index = this.itensAtividades.indexOf(listaItens[index]);

      let itemAtividade = this.itensAtividades[index];

      if (!itemAtividade.ProdutoId)
        return;

      let produto = new Produto();
      if (servico)
        produto = this.getServico(itemAtividade.ProdutoId);
      else
        produto = this.getProduto(itemAtividade.ProdutoId);

      itemAtividade.Produto = produto;

      if (itemAtividade.ProdutoId > 0 && !naoMudarPreco) {
        if (produto.PrecoUnitario && produto.PrecoUnitario > 0)
          itemAtividade.PrecoUnitario = produto.PrecoUnitario;
      }

      if (itemAtividade.Ordem == null || itemAtividade.Ordem == undefined) {
        this.toasty.show('Atenção!', 'O campo "Ordem" não pode ser vazio!', 'warning');
        return;
      }

      if (itemAtividade.PrecoUnitario <= 0) {
        this.toasty.show('Atenção!', 'O Valor não pode ser igual a 0!', 'warning');
        return
      }

      if (itemAtividade.ProdutoId > 0 &&
        itemAtividade.Dose) {
        this.carregando = true;

        if (itemAtividade.Id > 0)
          this.itensAtividadeService.putItensAtividade(itemAtividade.Id, itemAtividade).subscribe(
            res => {
              itemAtividade = res;
              this.itensAtividades[index] = itemAtividade;

              index = this.itensAtividadesSalvos.indexOf(this.itensAtividadesSalvos.filter(i => i.Id == itemAtividade.Id)[0]);
              this.itensAtividadesSalvos[index] = itemAtividade;

              this.carregando = false;
              this.custoEtapa(this.atividadesPlanejamento.filter(a => a.Id == atividadePlanejamentoId)[0]);
              this.custoPlanejamento();
              this.custoProcesso();
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        else
          this.itensAtividadeService.postItensAtividade(itemAtividade).subscribe(
            res => {
              itemAtividade = res;
              this.itensAtividades[index] = itemAtividade;

              if (!this.itensAtividadesSalvos)
                this.itensAtividadesSalvos = [];

              this.itensAtividadesSalvos.push(itemAtividade);
              this.carregando = false;
              this.custoEtapa(this.atividadesPlanejamento.filter(a => a.Id == atividadePlanejamentoId)[0]);
              this.custoPlanejamento();
              this.custoProcesso();
              //this.novoItemAtividade(atividadePlanejamentoId);
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
      }
    }, 100);
  }

  timoutDose: any;
  changeDose(atividadePlanejamentoId: number, index: number, pulverizacaoSulco: boolean, adubacaoLinha: boolean, tratamentoSetmentes: boolean, servico: boolean) {
    if (this.timoutDose)
      clearTimeout(this.timoutDose);

    this.timoutDose = setTimeout(() => {
      this.changeProduto(atividadePlanejamentoId, index, pulverizacaoSulco, adubacaoLinha, tratamentoSetmentes, servico, true);
    }, 1500);
  }

  excluirItemDetalhe(id: number, produtoId: number, index: number, atividadePlanejamentoId: number, isServico?: boolean) {
    let nome = this.getProduto(produtoId).Descricao;
    Swal.fire({
      title: 'Deletar ' + nome + '?',
      text: 'Se deletar, esta ação não poderá ser desfeita!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.itensAtividadeService.deleteItensAtividade(id).subscribe(
          res => {
            this.toasty.show('Feito!', 'Produto deletado!', 'success');
            let itemExcluido = !isServico ? this.getItensPorAtividade(atividadePlanejamentoId)[index] : this.getServicosPorAtividade(atividadePlanejamentoId)[index];
            this.itensAtividades.splice(this.itensAtividades.indexOf(itemExcluido), 1);
            this.itensAtividadesSalvos.splice(this.itensAtividadesSalvos.indexOf(itemExcluido), 1);
            this.carregando = false;
            this.custoEtapa(this.atividadesPlanejamento.filter(a => a.Id == atividadePlanejamentoId)[0]);
            this.custoPlanejamento();
            this.custoProcesso();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  custo(item: ItensAtividade): number {
    if (!item.ProdutoId)
      return null;

    return item.PrecoUnitario * item.Dose;
  }

  custoAtividade(atividade: AtividadePlanejamento, onLoad?: boolean): number {
    if (this.itensAtividadesSalvos == null)
      return null;

    let custoTotal: number = 0;

    this.itensAtividadesSalvos.filter(i => i.AtividadePlanejamentoId == atividade.Id && !i.DesprezarNoCusto).forEach(i => {
      custoTotal += this.custo(i);
    });

    return custoTotal > 0 ? custoTotal : null;
  }

  custoEtapa(atividade: AtividadePlanejamento, onLoad?: boolean): number {
    let custoTotal: number = 0;

    this.atividadesPlanejamento.filter(i => i.EtapaId == atividade.EtapaId && i.DiasAposPlantio == atividade.DiasAposPlantio).forEach(i => {
      custoTotal += this.custoAtividade(i, onLoad);
    });

    this.custoTotalEtapa.emit(custoTotal);
    return custoTotal > 0 ? custoTotal : null;
  }

  custoPlanejamento(): number {
    let custoTotal: number = 0;
    this.atividadesPlanejamentoSalvas.forEach(i => {
      custoTotal += this.custoAtividade(i);
    });

    this.custoTotalPlanejamento.emit(custoTotal);
    return custoTotal > 0 ? custoTotal : null;
  }

  custoProcesso(): number {
    let custoProcessoPlanejamento: number = 0;
    this.atividadesPlanejamentoSalvas.filter(x => x.ProcessoPlanejamentoId == this.etapa.ProcessoPlanejamentoId).forEach(i => {
      custoProcessoPlanejamento += this.custoAtividade(i);
    });

    this.custoProcessoPlanejamento.emit(custoProcessoPlanejamento);
    return custoProcessoPlanejamento > 0 ? custoProcessoPlanejamento : null;
  }

  getClassItemProduto(itemAtividade: ItensAtividade): string {
    if (this.isPlanoMestre || !itemAtividade.Id)
      return '';

    if (itemAtividade.ItensAtividadePlanoMestre == null)
      return 'item-avulso';

    if (itemAtividade.ProdutoId != itemAtividade.ItensAtividadePlanoMestre.ProdutoId)
      return 'item-alterado';

    return 'item-padrao';
  }

  getClassItemDose(itemAtividade: ItensAtividade): string {
    if (this.isPlanoMestre || !itemAtividade.Id)
      return '';

    if (itemAtividade.ItensAtividadePlanoMestre == null)
      return 'item-avulso';

    if (itemAtividade.Dose != itemAtividade.ItensAtividadePlanoMestre.Dose)
      return 'item-alterado';

    return 'item-padrao';
  }

  getHintItemProduto(itemAtividade: ItensAtividade): string {
    if (this.isPlanoMestre || !itemAtividade.Id)
      return 'Produto';

    if (itemAtividade.ItensAtividadePlanoMestre == null)
      return 'Item avulso';

    if (itemAtividade.ProdutoId != itemAtividade.ItensAtividadePlanoMestre.ProdutoId)
      return 'Item alterado';

    return 'Item conforme o plano mestre';
  }

  getHintItemSemente(itemAtividade: ItensAtividade): string {
    if (this.isPlanoMestre || !itemAtividade.Id)
      return 'Cultivar';

    if (itemAtividade.ItensAtividadePlanoMestre == null)
      return 'Cultivar avulso';

    if (itemAtividade.ProdutoId != itemAtividade.ItensAtividadePlanoMestre.ProdutoId)
      return 'Cultivar alterado';

    return 'Cultivar conforme o plano mestre';
  }

  getHintItemServico(itemAtividade: ItensAtividade): string {
    if (this.isPlanoMestre || !itemAtividade.Id)
      return 'Serviço';

    if (itemAtividade.ItensAtividadePlanoMestre == null)
      return 'Serviço avulso';

    if (itemAtividade.ProdutoId != itemAtividade.ItensAtividadePlanoMestre.ProdutoId)
      return 'Serviço alterado';

    return 'Serviço conforme o plano mestre';
  }

  getHintItemDose(itemAtividade: ItensAtividade, atividadePlanejamento: AtividadePlanejamento): string {
    let isServico = !this.getAtividade(atividadePlanejamento.AtividadeId).UtilizaInsumo;

    if (this.isPlanoMestre || !itemAtividade.Id)
      return !isServico ? 'Dose' : 'Quantidade';

    if (itemAtividade.ItensAtividadePlanoMestre == null)
      return 'Item avulso';

    if (itemAtividade.Dose != itemAtividade.ItensAtividadePlanoMestre.Dose)
      return 'Item alterado';

    return 'Item conforme o plano mestre';
  }

  //ng-select functions
  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMore(null);
  }

  onScrollToEndSemente() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMoreSemente(null);
  }

  onScrollToEndServico() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return;
    }
    this.fetchMoreServico(null);
  }

  timoutFectch: any;
  private fetchMore(termoBusca?: String) {
    console.log("sem cultivar")
    if (this.timoutFectch)
      clearTimeout(this.timoutFectch);

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;

      this.produtoService.getProdutosPaginandoSemCultivar(this.planejamento.Id, this.pagina, this.termoBusca, false, false).subscribe(ret => {
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.produtos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.produtos = termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.produtos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.adicionarERP();
      });
    }, 300);

  }

  timoutFectchSemente: any;
  private fetchMoreSemente(termoBusca?: String) {

    if (this.timoutFectchSemente)
      clearTimeout(this.timoutFectchSemente);

    this.timoutFectchSemente = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;

      this.produtoService.getProdutosPaginando(this.planejamento.Id, this.pagina, this.termoBusca, false, true).subscribe(ret => {
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.sementes.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.sementes = termoBusca != null ? ret.Lista : this.sementes.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.sementes.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.adicionarERP();
      });
    }, 300);
  }

  timoutFectchServico: any;
  private fetchMoreServico(termoBusca?: String) {

    if (this.timoutFectchServico)
      clearTimeout(this.timoutFectchServico);

    this.timoutFectchServico = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca;
      this.carregandoProduto = true;

      this.produtoService.getProdutosPaginando(this.planejamento.Id, this.pagina, this.termoBusca, true, false).subscribe(ret => {
        this.carregandoProduto = false;

        if (!this.ultimaPagina || termoBusca != null) {
          const ids = this.servicos.map(c => c.Id);
          const itensToAdd = ret.Lista.filter(c => !ids.includes(c.Id))
          this.servicos = termoBusca != null ? ret.Lista : this.servicos.concat(itensToAdd);
        }

        this.ultimaPagina = ret.TotalPaginas <= this.pagina;

        if (!this.ultimaPagina || termoBusca != null)
          this.pagina = termoBusca != null ? 1 : this.pagina + 1;

        this.servicos.forEach(p => p.GrupoProduto = this.getGrupoProduto(p.GrupoProdutoId).Descricao);
        this.adicionarERP();
      });
    }, 300);
  }

  getProcessoPlanejamento(id: number): ProcessoPlanejamento {
    return this.processosPlanejamento.find(p => p.Id == id);
  }

  getUnidadeMedida(produtoId): string {
    if (this.dicionarioProdutos && this.dicionarioProdutos[produtoId])
      return this.dicionarioProdutos[produtoId].UnidadeMedida;
    else
      return 'UN';
  }

  getUnidadeMedidaItensAtividade(item: ItensAtividade): string{
    return item.UnidadeMedida ? item.UnidadeMedida : this.getUnidadeMedida(item.ProdutoId);
  }
}
