import { LocalEstoqueService } from './../../../services/local-estoque.service';
import { LocalEstoque } from './../../../classes/LocalEstoque';
import Swal from 'sweetalert2';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { ApontamentoService } from './../../../services/apontamento.service';
import { TipoImprodutividadeService } from './../../../services/tipo-improdutividade.service';
import { ImprodutividadeEquipamentoService } from './../../../services/improdutividade-equipamento.service';
import { TipoImprodutividade } from './../../../classes/TipoImprodutividade';
import { ImprodutividadeEquipamento } from './../../../classes/ImprodutividadeEquipamento';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { ItemApontamentoService } from './../../../services/item-apontamento.service';
import { EquipamentoApontamentoService } from './../../../services/equipamento-apontamento.service';
import { UiModalComponent } from './../../../theme/shared/components/modal/ui-modal/ui-modal.component';
import { ItemOrdemServicoService } from './../../../services/item-ordem-servico.service';
import { AtividadeService } from './../../../services/atividade.service';
import { ProdutoService } from './../../../services/produto.service';
import { Produto } from './../../../classes/Produto';
import { EquipamentoApontamento } from './../../../classes/EquipamentoApontamento';
import { ItemApontamento } from './../../../classes/ItemApontamento';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { EnumStatusOS, OrdemServico } from './../../../classes/OrdemServico';
import { Apontamento } from './../../../classes/Apontamento';
import { FuncionarioService } from './../../../services/funcionario.service';
import { Funcionario } from './../../../classes/Funcionario';
import { Equipamento } from './../../../classes/Equipamento';
import { ItemOrdemServico } from 'src/app/classes/ItemOrdemServico';
import { StorageService } from 'src/app/services/storage.service';
import { enumErpIntegrado } from 'src/app/classes/Empresa';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { ApontamentoTranslate } from './apontamento.translate';
import { TranslateService } from '@ngx-translate/core';
import { ApontamentoIndicadores } from 'src/app/classes/ApontamentoIndicadores';
import { forkJoin } from 'rxjs';
import { MovimentoEstoqueService } from 'src/app/services/movimento-estoque.service';
import { AreaService } from 'src/app/services/area.service';
import { Area } from 'src/app/classes/Area';

@Component({
  selector: 'app-apontamento',
  templateUrl: './apontamento.component.html',
  styleUrls: ['./apontamento.component.scss']
})
export class ApontamentoComponent extends ApontamentoTranslate implements OnInit, OnChanges, AfterViewInit {

  @Input() ordemServico: OrdemServico;
  @Input() inserindo: boolean;
  @Output() fechou: EventEmitter<any> = new EventEmitter();
  @Output() apontamentoExcluido: EventEmitter<any> = new EventEmitter();
  @Output() apontamentoSalvo: EventEmitter<any> = new EventEmitter();
  @Output() fechouTabela: EventEmitter<any> = new EventEmitter();
  @Output() pararCarregamento: EventEmitter<any> = new EventEmitter();

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  apontamento: Apontamento = new Apontamento();

  funcionarios: Funcionario[];
  equipamentos: Equipamento[] = [];
  equipamentosApontamento: EquipamentoApontamento[] = [];
  implementos: Equipamento[] = [];
  apontamentos: Apontamento[] = [];

  carregando: boolean;
  isServico: boolean = false;
  itensApontamento: ItemApontamento[] = [];
  equipamentoApontamento: EquipamentoApontamento;

  improdutividadesEquipamento: ImprodutividadeEquipamento[];
  tiposImprodutividade: TipoImprodutividade[];

  isSubmit: boolean = false;
  haAplicada: number = 0;

  //Estoque
  controlaEstoque: boolean;
  locaisEstoque: LocalEstoque[] = [];
  tipoIntegracao: string = "0";
  apontamentoIndicadores: ApontamentoIndicadores = new ApontamentoIndicadores()
  origem: string = "";

  constructor(private funcionarioService: FuncionarioService,
    private produtoService: ProdutoService,
    private atividadeService: AtividadeService,
    private itemOrdemServicoService: ItemOrdemServicoService,
    private itemApontamentoService: ItemApontamentoService,
    private equipamentoApontamentoService: EquipamentoApontamentoService,
    private equipamentoService: EquipamentoService,
    private improdutividadeEquipamentoService: ImprodutividadeEquipamentoService,
    private tipoImprodutividadeService: TipoImprodutividadeService,
    private apontamentoService: ApontamentoService,
    private toasty: CustomToastyService,
    private localEstoqueService: LocalEstoqueService,
    private storageService: StorageService,
    private ordemServicoService: OrdemServicoService,
    private utils: UtilsService,
    private translate: TranslateService,
    private movimentoEstoqueService: MovimentoEstoqueService,
    private areaService: AreaService,
    private cdr: ChangeDetectorRef) {
    super(translate)
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ordemServico) {
      if (this.ordemServico && this.ordemServico.Id > 0) {
        this.listarApontamentos();
        this.carregando = false;
      }
    }
  }

  ngOnInit() {
    this.carregarDadosIniciais();
    this.controlaEstoque = this.storageService.getEmpresa().ControlaEstoque;
  }

  carregarDadosIniciais() {
    if (this.ordemServico) {
      this.carregando = true;
      setTimeout(() => {
        this.areaService.getArea(this.ordemServico.AreaId).subscribe(res => {
          let area: Area = res;
          if (this.ordemServico.FazendaId ? this.ordemServico.FazendaId : 0 == 0)
            this.ordemServico.FazendaId = area.FazendaId;
          forkJoin([
            this.funcionarioService.getFuncionarios(),
            this.equipamentoService.getEquipamentos(),
            this.tipoImprodutividadeService.getTiposImprodutividade(),
            this.atividadeService.getAtividade(this.ordemServico.AtividadeId),
            this.apontamentoService.getApontamentos(this.ordemServico.Id),
            this.localEstoqueService.listarPorFazenda(this.ordemServico.FazendaId ? this.ordemServico.FazendaId : 0),
            this.equipamentoApontamentoService.getEquipamentosApontamentoPorOs(this.ordemServico.Id)
          ]).subscribe(
            (resultados) => {
              this.funcionarios = resultados[0];
              this.equipamentos = resultados[1].filter(x => !x.FlagImplemento);
              this.implementos = resultados[1].filter(x => x.FlagImplemento);
              this.tiposImprodutividade = resultados[2];
              const atividade = resultados[3];
              this.apontamentos = resultados[4];
              this.locaisEstoque = resultados[5];
              this.equipamentosApontamento = resultados[6];
              this.isServico = !atividade.UtilizaInsumo;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            }
          );
        }, err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
      }, 2000);
    }
  }


  salvar(form: any, concluir?: boolean) {
    if (!form.valid ||
      !this.apontamento.Data) {
      this.isSubmit = true;
      return;
    }

    const erros = this.validarApontamento();

    if (erros.length > 0) {
      this.utils.handleWarning(erros.join('<br>'))
      return;
    }

    if (this.apontamento)
      this.carregando = true;

    this.equipamentoApontamento.HoraInicio = "00:00:00";
    this.equipamentoApontamento.HoraFim = "00:00:00";

    this.itensApontamento.forEach(i => {
      i.ApontamentoId = this.apontamento.Id;
      i.Id = i.Id < 0 ? -1 : i.Id;
    });
    this.apontamento.Formulario = this.apontamento.Formulario.toString();

    this.apontamentoService.SalvarCompleto(this.apontamento,
      this.equipamentoApontamento,
      this.itensApontamento.filter(i => i.ProdutoId),
      this.improdutividadesEquipamento.filter(i => i.TipoImprodutividadeId)
    ).subscribe(
      res => {
        this.completarApontamento(res.retorno, concluir);
        if (this.origem != "tabela") {
          this.listarApontamentos();
          this.carregando = false;
        } else {
          this.carregando = false;
          this.fechou.emit();
        }
        this.carregando = false;
        this.fechar();
        this.apontamentoSalvo.emit(res.os);
        this.ordemServicoService.emitirEventoListarItens();
      },
      err => {
        this.carregando = false;
        this.utils.getErro(err);
        if(err.status === 200)
          this.completarApontamento("OK", concluir);
        this.carregando = false;
        this.ordemServicoService.emitirEventoListarItens();
      }
    );
  }

  completarApontamento(res: any, concluir?: boolean) {
    this.toasty.show(this.translate.instant('FEITO'), this.apontamento.Id ? this.translate.instant('APONTAMENTO_ALTERADO') : this.translate.instant('APONTAMENTO_CADASTRADO'), 'success');
    this.isSubmit = false;
    if (this.storageService.getEmpresa().ErpIntegrado != enumErpIntegrado.Nenhum) {
      let nomeErp = "";
      switch (this.storageService.getEmpresa().ErpIntegrado) {
        case enumErpIntegrado.LinksisERP:
          nomeErp = "LinkERP";
          break;
        case enumErpIntegrado.Sankhya:
          nomeErp = "Sankhya";
          break;
        case enumErpIntegrado.Totvs_RM:
          nomeErp = "Totvs RM";
          break;
        default:
          break;
      }

      if (res == "OK") {
        this.toasty.show(this.translate.instant('INTEGRACAO_ERP'), this.translate.instant('MOVIMENTO_DE_ESTOQUE_CRIADO_NO_') + nomeErp, 'success');
      }
      else {
        Swal.fire(this.translate.instant('FEITO'), this.translate.instant('SEU_APONTAMENTO_FOI_GERADO_MAS_O_MOVIMENTO_DE_ESTOQUE_NAO_FOI_CRIADO_NO_') + nomeErp + ".<br/> " + this.ERRO_ + res, 'warning');
      }
    }

    if (concluir) {
      this.carregando = true;
      this.ordemServicoService.getOrdemServico(this.apontamento.OrdemServicoId).subscribe(resOS => {
        let ordemServicoAux: OrdemServico = resOS;
        ordemServicoAux.Status = EnumStatusOS.Concluida;
        this.ordemServicoService.putOrdemServico(this.apontamento.OrdemServicoId, ordemServicoAux).subscribe(resOs2 => {
          this.toasty.show(this.translate.instant('FEITO'), this.translate.instant('ORDEM_DE_SERVICO_CONCLUIDA'), 'success');
          this.carregando = false;
        }, err => {
          this.carregando = false;
          this.utils.getErro(err)
        });
      }, err => {
        this.carregando = false;
        this.utils.getErro(err)
      });
    }
  }

  changeHorimetro() {
    let equipamentoApontamento = this.equipamentoApontamento;
    equipamentoApontamento.HorasTrabalhadas = 0;
    equipamentoApontamento.Rendimento = 0;
    if (equipamentoApontamento.HorimetroInicial &&
      equipamentoApontamento.HorimetroFinal) {
      equipamentoApontamento.HorasTrabalhadas = equipamentoApontamento.HorimetroFinal - equipamentoApontamento.HorimetroInicial - equipamentoApontamento.ImprodutividadeTotal;

      if (equipamentoApontamento.Area && equipamentoApontamento.HorasTrabalhadas > 0)
        equipamentoApontamento.Rendimento = equipamentoApontamento.Area / equipamentoApontamento.HorasTrabalhadas;
    }
  }

  getHorimetrofinal() {
    if (this.equipamentoApontamento.HorimetroInicial <= 0) {
      this.equipamentoApontamentoService.getHorimetroFinal(this.equipamentoApontamento.EquipamentoId).subscribe(
        ret => this.equipamentoApontamento.HorimetroInicial = ret,
        err => this.utils.getErro(err)
      );
    }
  }

  fechar() {
    this.carregando = false;
    this.Modal.hide();
    if (this.origem != "tabela")
      this.fechou.emit();
    else
      this.fechouTabela.emit();
  }

  //improdutividade
  novaImprodutividade() {
    let equipamentoSelecionado = this.equipamentoApontamento.EquipamentoId;

    if (!this.improdutividadesEquipamento)
      this.improdutividadesEquipamento = [];

    if (this.improdutividadesEquipamento.length == 0 || this.improdutividadesEquipamento[this.improdutividadesEquipamento.length - 1].TipoImprodutividadeId > 0) {
      let novo = new ImprodutividadeEquipamento();
      novo.EquipamentoApontamentoId = equipamentoSelecionado;
      novo.Id = this.improdutividadesEquipamento.length + 1;

      this.improdutividadesEquipamento.push(novo);
    }
  }

  changeImprodutividade(improdutividade: ImprodutividadeEquipamento) {
    if (improdutividade.Id < 1)
      improdutividade.Id = 0;
    if (!improdutividade.EquipamentoApontamentoId || improdutividade.EquipamentoApontamentoId < 1)
      improdutividade.EquipamentoApontamentoId = 0;

    if (improdutividade.HoraInicio && improdutividade.HoraInicio.length <= 5)
      improdutividade.HoraInicio = improdutividade.HoraInicio + ":00";

    if (improdutividade.HoraFim && improdutividade.HoraFim.length <= 5)
      improdutividade.HoraFim = improdutividade.HoraFim + ":00";

    this.calcTempoParado();

    if (improdutividade.TipoImprodutividadeId > 0 &&
      ((improdutividade.HoraInicio &&
        improdutividade.HoraFim) ||
        (improdutividade.HorimetroInicial &&
          improdutividade.HorimetroFinal))) {
      this.novaImprodutividade();
    }
  }

  timoutImprodutividade: any;
  changeHoraImprodutividade(improdutividade: ImprodutividadeEquipamento) {
    this.calcTempoParado();

    if (this.timoutImprodutividade)
      clearTimeout(this.timoutImprodutividade);

    this.timoutImprodutividade = setTimeout(() => {
      this.changeImprodutividade(improdutividade);
    }, 500);
    if (improdutividade.Id < 1)
      improdutividade.Id = 0;
  }

  // TODO Rever calculo Improdutividade
  calcTempoParado() {
    this.equipamentoApontamento.ImprodutividadeTotal = 0;

    this.improdutividadesEquipamento.filter(i => i.TipoImprodutividadeId).forEach(i => {
      if (i.HorimetroInicial > 0 && i.HorimetroFinal > 0)
        this.equipamentoApontamento.ImprodutividadeTotal += i.HorimetroFinal - i.HorimetroInicial;
      else if (i.HoraFim && i.HoraInicio) {
        let horaInicio = new Date("1900-01-01T" + i.HoraInicio);
        let horaFim = new Date("1900-01-01T" + i.HoraFim);

        this.equipamentoApontamento.ImprodutividadeTotal += horaFim.getHours() - horaInicio.getHours();
      }
    });

    this.changeHorimetro();
  }

  deleteImprodutividade(index: number) {
    let improdutividade = this.improdutividadesEquipamento[index];

    if (improdutividade.Id < 0)
      this.improdutividadesEquipamento.splice(index, 1);
    else
      improdutividade.FlagExcluido = true;

    if (index > this.improdutividadesEquipamento.filter(e => !e.FlagExcluido).length - 1)
      this.novaImprodutividade();
  }

  changeProduto(itemApontamento: ItemApontamento, produto?: Produto) {
    if (produto) {
      if (itemApontamento.ProdutoId != produto.Id) {
        itemApontamento.ProdutoId = produto.Id;
        if (!itemApontamento.PrecoUnitario)
          itemApontamento.PrecoUnitario = produto.PrecoUnitario;
        itemApontamento.UN = produto.UnidadeMedida;
      }

      if (this.isServico && !itemApontamento.QtdAplicada)
        itemApontamento.QtdAplicada = 1;
    }

    itemApontamento.ValorTotal = itemApontamento.QtdAplicada * itemApontamento.PrecoUnitario;
  }

  timoutDose: any;
  changeDose(itemApontamento: ItemApontamento) {
    if (this.timoutDose)
      clearTimeout(this.timoutDose);

    this.timoutDose = setTimeout(() => {
      this.changeProduto(itemApontamento);
    }, 500);
  }

  changeQtdRetorno(itemOS: ItemOrdemServico) {
    if (itemOS.QtdRetirada > 0) {
      itemOS.QtdAplicada = itemOS.QtdRetirada - itemOS.QtdRetorno;
    }
  }

  changeQtdAplicada(itemOS: ItemOrdemServico) {
    if (itemOS.QtdRetirada > 0) {
      itemOS.QtdRetorno = itemOS.QtdRetirada - itemOS.QtdAplicada;
    }
  }

  deleteItem(index: number) {
    let item = this.itensApontamento[index];

    if (item.Id < 0)
      this.itensApontamento.splice(index, 1);
    else {
      item.FlagExcluido = true;
      this.itensApontamento.splice(index, 1);
    }
  }

  GetEstoque(produtoId: number, localId: number): string {
    this.produtoService.geEstoqueProduto(produtoId, localId).subscribe(
      ret => {
        if (ret)
          return ret.Quantidade;
      },
      err => this.utils.getErro(err)
    )
    return "";
  }

  PuxarEstoque(item: ItemApontamento) {
    this.produtoService.geEstoqueProduto(item.ProdutoId, item.LocalEstoqueId).subscribe(ret => {
      if (ret)
        item.Quantidade = ret.QuantidadeUn;
      else
        item.Quantidade = this.NAO_DEFINIDO;
    },
      error => {
        item.Quantidade = this.NAO_DEFINIDO;
        this.utils.getErro(error)
      });
  }

  abrirApontamento(apontamento: Apontamento) {
    this.carregando = true;
    this.apontamento = apontamento;
    this.atualizarApontamentos();
    this.inserindo = false;
    this.Modal.show();
  }

  novoApontamentoComOrdem(ordemServico: OrdemServico) {
    this.carregando = true;
    this.ordemServico = ordemServico;
    this.origem = "tabela";
    this.carregarDadosIniciais();
    this.novoApontamento();
  }

  novoApontamento() {
    this.carregando = true;
    this.apontamento = new Apontamento();
    this.apontamento.OrdemServicoId = this.ordemServico.Id;
    this.apontamento.FuncionarioId = this.ordemServico.FuncionarioLiberacaoId;

    this.equipamentoApontamento = new EquipamentoApontamento();
    this.equipamentoApontamento.Area = this.ordemServico.AreaPrevista;
    this.equipamentoApontamento.ImprodutividadeTotal = 0;
    if (this.ordemServico.EquipamentoId)
      this.equipamentoApontamento.EquipamentoId = this.ordemServico.EquipamentoId;
    if (this.ordemServico.ImplementoId)
      this.equipamentoApontamento.ImplementoId = this.ordemServico.ImplementoId;
    this.novaImprodutividade();

    this.itemOrdemServicoService.getItensOrdemServico(this.ordemServico.Id).subscribe(
      (res: ItemOrdemServico[]) => {
        const itensOrdemServico = res;
        this.itensApontamento = [];
        this.carregando = true;
        itensOrdemServico.forEach(i => {
          this.produtoService.getProduto(i.ProdutoId).subscribe(
            (ret: Produto) => {
              let itemApontamento = new ItemApontamento();
              itemApontamento.UN = ret.UnidadeMedida;
              itemApontamento.ProdutoId = i.ProdutoId;
              itemApontamento.QtdPrevista = i.QtdPrevista;
              itemApontamento.QtdJaAplicada = i.QtdAplicada;
              itemApontamento.QtdAplicada = (i.QtdPrevista - i.QtdAplicada);
              itemApontamento.QtdRetirada = (i.QtdPrevista - i.QtdAplicada);
              itemApontamento.QtdRetorno = itemApontamento.QtdRetirada - itemApontamento.QtdAplicada;
              itemApontamento.PrecoUnitario = i.PrecoUnitario;
              itemApontamento.isServico = i.isServico;
              itemApontamento.ItemOsId = i.Id;
              if (itemApontamento.LocalEstoqueId > 0)
                itemApontamento.Quantidade = this.GetEstoque(itemApontamento.ProdutoId, itemApontamento.LocalEstoqueId);
              if (itemApontamento.QtdRetirada > 0 || i.isServico) {
                this.itensApontamento.push(itemApontamento);
              }
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        });

        // Remove itens duplicados
        this.itensApontamento = this.itensApontamento.filter((item, index) => {
          return this.itensApontamento.indexOf(item) === index;
        });
        this.equipamentoApontamentoService.AreaApontadaPorOs(this.ordemServico.Id).subscribe(
          ret => {
            this.haAplicada = ret;
            this.equipamentoApontamento.Area = this.equipamentoApontamento.Area - ret;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          }
        )
        this.inserindo = true;
        setTimeout(() => {
          this.carregando = false;
        }, 3000);
        this.Modal.show();
        this.pararCarregamento.emit();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    );
  }

  excluirApontamento(apontamento: Apontamento) {
    Swal.fire({
      title: this.translate.instant('DELETAR_O_APONTAMENTO') + ' ' + apontamento.Id + '?',
      text: this.translate.instant('SE_DELETAR_ESTA_ACAO_NAO_PODERA_SER_DESFEITA'),
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('CANCELAR'),
      confirmButtonText: this.translate.instant('SIM'),
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.carregando = true;
        this.apontamentoService.deleteApontamento(apontamento.Id).subscribe(
          res => {
            this.apontamentos.splice(this.apontamentos.indexOf(apontamento), 1);
            this.utils.handleSuccess(this.translate.instant('APONTAMENTO_DELETADO'));

            if (this.storageService.getEmpresa().ErpIntegrado != enumErpIntegrado.Nenhum) {
              let nomeErp = "";
              switch (this.storageService.getEmpresa().ErpIntegrado) {
                case enumErpIntegrado.LinksisERP:
                  nomeErp = "LinkERP";
                  break;
                case enumErpIntegrado.Sankhya:
                  nomeErp = "Sankhya";
                  break;
                case enumErpIntegrado.Totvs_RM:
                  nomeErp = "Totvs RM";
                  break;
                default:
                  break;
              }

              if (res.retorno == "OK") {
                this.toasty.show(this.translate.instant('INTEGRACAO') + ' ERP', this.translate.instant('MOVIMENTO_DE_ESTOQUE_CANCELADO_NO') + " " + nomeErp, 'success');
              }
              else {
                Swal.fire(this.ATENCAO, this.translate.instant('MOVIMENTO_DE_ESTOQUE_NAO_FOI_CANCELADO_NO') + " " + nomeErp + "<br/> " + this.translate.instant('ERRO') + ": " + res, 'warning');
              }
            }
            this.carregando = false;

            this.listarApontamentos();
            this.apontamentoExcluido.emit(res.os);
            this.ordemServicoService.emitirEventoListarItens();
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          }
        );
      }
    });
  }

  atualizarApontamentos() {
    this.equipamentoApontamentoService.getEquipamentosApontamentoPorApontamento(this.apontamento.Id).subscribe(
      (res: EquipamentoApontamento) => {
        this.equipamentoApontamento = res;

        this.itemApontamentoService.getItensApontamento(this.apontamento.Id).subscribe(
          (res: ItemApontamento[]) => {
            this.itensApontamento = res;
            this.carregando = false;
          }, err => {
            this.utils.getErro(err);
          }
        );

        if (this.equipamentoApontamento.Id) {
          this.improdutividadeEquipamentoService.getImprodutividadesEquipamento(this.equipamentoApontamento.Id).subscribe(
            res => {
              this.improdutividadesEquipamento = res;
              this.carregando = false;
            },
            err => this.utils.getErro(err)
          );
        }
      }, err => {
        this.utils.getErro(err)
        this.carregando = false;
      }
    );
  }

  getFuncionario(id: number): Funcionario {
    if (!this.funcionarios)
      return null;

    let funcionario = this.funcionarios.find(x =>
      x.Id == id
    );

    return funcionario ? funcionario : new Funcionario();
  }

  getEquipamento(id: number): Equipamento {
    if (!this.equipamentos)
      return null;

    let equipamento = this.equipamentos.filter(x =>
      x.Id == id
    );
    return equipamento.length > 0 ? equipamento[0] : new Equipamento();
  }

  getEquipamentoApontamento(apontamentoId: number): EquipamentoApontamento {
    let equipamento = this.equipamentosApontamento.filter(x =>
      x.ApontamentoId == apontamentoId
    );
    return equipamento ? equipamento[0] : new EquipamentoApontamento();
  }

  temItensServico(): boolean {
    return this.itensApontamento.filter(c => c.isServico).length > 0;
  }

  temItensInsumo(): boolean {
    return this.itensApontamento.filter(c => !c.isServico).length > 0;
  }

  changeEstoque(id, itens) {
    itens.forEach((i, j) => {
      itens[j].LocalEstoqueId = id;
    })
  }

  listarApontamentos() {
    this.carregando = true;
    forkJoin([
      this.apontamentoService.getApontamentos(this.ordemServico.Id),
      this.equipamentoApontamentoService.getEquipamentosApontamentoPorOs(this.ordemServico.Id)
    ]).subscribe(
      res => {
        this.apontamentos = res[0];
        this.equipamentosApontamento = res[1];
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
        this.fechouTabela.emit();
      }
    )
  }

  sincronizarApontamento(apontamento: Apontamento) {
    apontamento.sincronizando = true;
    this.movimentoEstoqueService.RefazerApontamentoRMApontamento(apontamento.Id)
      .subscribe(
        res => {
          if (res == null) {
            this.utils.handleWarning("Não foi possível sincronizar.")
            return
          }
          apontamento.IdMovimentoErp = res;
          this.utils.handleSuccess("Apontamento sincronizado com sucesso.");
          apontamento.sincronizando = false;
        }, err => {
          apontamento.sincronizando = false;
          this.utils.getErro(err)
        })
  }

  private validarApontamento(): Array<string> {
    const requiredFields = [
      //{ condicao: !this.equipamentoApontamento.Area, message: "Area do apontamento não pode ser zero!" },
      { condicao: this.equipamentoApontamento.Area > this.ordemServico.AreaPrevista, message: "Area do apontamento não pode ser maior que a área prevista!" },
      { condicao: this.equipamentoApontamento.HorimetroInicial > this.equipamentoApontamento.HorimetroFinal, message: 'Horímetro inicial não pode ser maior que o horímetro final' },
      { condicao: this.equipamentoApontamento.HorimetroInicial < 0 || this.equipamentoApontamento.HorimetroFinal < 0, message: 'Horímetro não pode ter valor negativo' },
      //{ condicao: this.equipamentoApontamento.HorimetroInicial <= 0 || this.equipamentoApontamento.HorimetroFinal <= 0, message: 'Horímetro de início e fim devem ser preenchidos' },
    ];

    const erros = requiredFields.reduce((errors, { condicao, message }) => {
      if (condicao) {
        errors.push(message);
      }
      return errors;
    }, []);

    return erros;
  }

}
