import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Area } from 'src/app/classes/Area';
import { Atividade } from 'src/app/classes/Atividade';
import { enumProcessoPlanejamento } from 'src/app/classes/AtividadePlanejamento';
import { Cultura } from 'src/app/classes/Cultura';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { OrdemServico } from 'src/app/classes/OrdemServico';
import { Planejamento } from 'src/app/classes/Planejamento';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { Safra } from 'src/app/classes/Safra';
import { Setor } from 'src/app/classes/Setor';
import { AreaService } from 'src/app/services/area.service';
import { AtividadeService } from 'src/app/services/atividade.service';
import { CulturaService } from 'src/app/services/cultura.service';
import { EtapaService } from 'src/app/services/etapa.service';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { PlanejamentoService } from 'src/app/services/planejamento.service';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { SafraService } from 'src/app/services/safra.service';
import { SetorService } from 'src/app/services/setor.service';
import { DatepickerComponent } from 'src/app/theme/shared/components/datepicker/datepicker.component';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-detalhes-ordem-servico',
  templateUrl: './detalhes-ordem-servico.component.html',
  styleUrls: ['./detalhes-ordem-servico.component.scss']
})
export class DetalhesOrdemServicoComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() setorId: number;
  @Input() isSubmit: boolean;
  @Input() inserindo: boolean = false;
  @Input() ordemServico: OrdemServico;
  @Input() organizaPorSetor: boolean = false;
  @Output() OrdemServicoChangeEvento = new EventEmitter<OrdemServico>();
  @Output() AtividadeChangeEvento = new EventEmitter<any>();

  @ViewChild('DataPrevistaFinal', { static: false })
  DataPrevistaFinal: DatepickerComponent;

  safra: Safra;
  carregando: boolean = false;
  atividades: Atividade[];
  @Input() culturas: Cultura[];
  @Input() areas: Area[];
  @Input() safras: Safra[];
  // TODO verificar
  //etapas: Etapa[];
  area: Area = new Area();
  planejamento: Planejamento = new Planejamento;
  setores: Setor[];

  multiplo: boolean = false;

  processosPlanejamento: ProcessoPlanejamento[];
  processoPlantio: number;
  gruposOperacao: GrupoOperacao[];

  constructor(public ordensServicoService: OrdemServicoService,
    private atividadeService: AtividadeService,
    private grupoOperacaoService: GrupoOperacaoService,
    private planejamentoService: PlanejamentoService,
    private safraService: SafraService,
    private culturaService: CulturaService,
    private areaService: AreaService,
    private etapaService: EtapaService,
    private utils: UtilsService,
    private setorService: SetorService,
    private processoPlanejamentoService: ProcessoPlanejamentoService,
    private cdr: ChangeDetectorRef,
    ) {


    this.processoPlantio = enumProcessoPlanejamento.Plantio;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.setorId && this.areas) {
      this.areas = this.areas.filter(x => x.SetorId == this.setorId);
    }
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  ngOnInit() {
    setTimeout(() => {

      forkJoin([
        this.processoPlanejamentoService.getProcessosPlanejamento(),
        this.setorService.getSetores(),
        this.atividadeService.getAtividadesParaUso(),
        this.grupoOperacaoService.getGruposOperacaoEmUso(),
      ]).subscribe(
        res => {
          this.processosPlanejamento = res[0];
          this.setores = res[1];
          const atividades: Atividade[] = res[2];
          this.gruposOperacao = res[3];
          atividades.forEach(a => {
            let grupo = this.gruposOperacao.filter(g => g.Id == a.GrupoOperacaoId);
            a.GrupoOperacaoDescricao = grupo.length > 0 ? grupo[0].Descricao : '';
          });
          this.atividades = atividades;
        },
        err => this.utils.getErro(err)
      );

      if(this.ordemServico.Id){
        this.getCulturas();
        this.selecionarSafra();
      }
    }, 600);
  }

  osTimeout: any;
  ordemServicoChange() {
    if (this.osTimeout)
      clearTimeout(this.osTimeout);
    this.osTimeout = setTimeout(() => {
      this.OrdemServicoChangeEvento.emit(this.ordemServico)
    }, 500);
  }

  getCulturas() {
    this.culturaService.getCulturasPorSafras([this.ordemServico.SafraId]).subscribe(ret => {
      this.culturas = ret
      this.ordemServico.CulturaId = this.culturas[0].Id;
    })
    this.ordemServicoChange();
  }

  selecionarSafra() {
    this.planejamentoService.getPlanejamento(this.ordemServico.PlanejamentoId).subscribe((ret: Planejamento) => {
      let planejamento = ret;
      this.safraService.getSafra(planejamento.SafraId).subscribe(ret => {
        this.safra = ret;
      });
    });
  }

  getCultura(id: number): Cultura {
    if (!this.culturas)
      return null;

    let cultura = this.culturas.filter(x =>
      x.Id == id
    );
    return cultura.length > 0 ? cultura[0] : new Cultura();
  }

  filtrarAtividade(term: string, item: Atividade) {
    term = term.toLowerCase();
    return item.Descricao.toLowerCase().indexOf(term) > -1 || (item.GrupoOperacaoDescricao ? item.GrupoOperacaoDescricao.toLowerCase().indexOf(term) > -1 : false);
  }

  selecionaFazenda(id: number) {
    this.ordemServico.FazendaId = id;
    if (this.inserindo){
      this.setorId = null;
      this.ordemServico.AreaId = null;
      this.ordemServico.SafraId = null;
      this.ordemServico.CulturaId = null;
    }

    forkJoin([
      this.areaService.getAreasPorFazenda(id),
      this.setorService.getSetoresPorFazenda(id),
      this.safraService.getSafrasPorAnoFazenda(0, id)
    ]).subscribe(
      res => {
        this.areas = res[0];
        this.setores = res[1];
        this.safras = res[2];
      },
      err => this.utils.getErro(err)
    )

    this.ordemServicoChange();
  }


  validacaoTamanhoAreaTimeout: any
  validarTamanhoArea() {
    if(this.multiplo)
      return;
    if (this.validacaoTamanhoAreaTimeout)
      clearTimeout(this.validacaoTamanhoAreaTimeout);

    if (!this.area.Id) {
      this.areaService.getArea(this.ordemServico.AreaId).subscribe(
        res => {
          this.area = res
          this.validarTamanhoArea();
          return;
        },
        err => this.utils.getErro(err)
      )
    }


    this.validacaoTamanhoAreaTimeout = setTimeout(() => {
      if(!this.ordemServico.AreaId){
        this.utils.handleWarning("Selecione um talhão");
        this.ordemServico.AreaPrevista = 0;
        this.ordensServicoService.emitirEventoAreaChange(true);
        return
      }

      if(this.ordemServico.AreaPrevista > this.area.AreaTotal || this.ordemServico.AreaPrevista <= 0){
        this.utils.handleWarning("Tamanho de área inconsistente!");
        this.ordemServico.AreaPrevista = this.area.AreaTotal;
        this.ordensServicoService.emitirEventoAreaChange(true);
        return;
      }

      this.ordemServicoChange();
      this.ordensServicoService.emitirEventoAreaChange(true);
    }, 1000);
  }

  selecionaArea() {
    if(this.multiplo) {
      this.ordemServico.AreaPrevista = this.areas
        .filter(x => this.ordemServico.AreasMultiplas.includes(x.Id))
        .reduce((total, area) => total + area.AreaTotal, 0)
      this.ordemServicoChange();
      this.ordensServicoService.emitirEventoAreaChange(true);
    }
    else{
      if(this.ordemServico.AreaId)
        this.areaService.getArea(this.ordemServico.AreaId).subscribe(
          ret => {
            this.area = ret;
            this.ordemServico.AreaPrevista = this.area.AreaTotal;
            this.ordemServicoChange();
            this.ordensServicoService.emitirEventoAreaChange(true);
          }
        );
    }
  }

  changeProcesso() {
    if (this.ordemServico.ProcessoPlanejamentoId) {
      this.atividadeService.getAtividadesPorProcesso(this.ordemServico.ProcessoPlanejamentoId).subscribe((res: Atividade[]) => {
        this.atividades = res;
        this.ordemServico.AtividadeId = null;
        this.ordemServicoChange();
        this.AtividadeChangeEvento.emit();
      }, err => this.utils.getErro(err));
    }
  }

  filtrarAreas() {
    this.areaService.getAreasPorFazenda(this.ordemServico.FazendaId).subscribe(
      ret => {
        this.areas = ret.filter(x => x.SetorId == this.setorId);
        this.ordemServico.AreaId = null;
        this.ordemServico.AreasMultiplas = [];
      }
    );
  }

  getProcessosPlanejamento(id: number): ProcessoPlanejamento {
    if (this.processosPlanejamento)
      return this.processosPlanejamento.find(x => x.Id == id);
    else
      return new ProcessoPlanejamento();
  }

  tempoUpdate: any;
  validate(data) {
    if (this.tempoUpdate)
      clearTimeout(this.tempoUpdate);
    this.tempoUpdate = setTimeout(() => {
      let dataEValida = this.dataEValida(data);
      if(dataEValida) {
        this.ordemServico.DataPrevistaFinal = data;
        this.ordemServicoChange();
      } else {
        this.utils.handleWarning("Fim Previsto deve ser maior que Início Previsto!");
        this.ordemServico.DataPrevistaFinal = this.ordemServico.DataPrevista;
        this.DataPrevistaFinal.setData(this.ordemServico.DataPrevista.toISOString().split('T')[0])
      }
    }, 1800);
  }

  dataEValida(data){
    if(typeof this.ordemServico.DataPrevista == 'string'){
      return this.ordemServico.DataPrevista <= data.toISOString();
    }
    return this.ordemServico.DataPrevista <= data;
  }

  changeMultiplosTalhoes() {
    this.ordemServico.AreasMultiplas = [];
    this.ordemServico.AreaPrevista = 0;
    this.ordemServico.AreaId = null;
    this.ordensServicoService.emitirEventoAreaChange(true);
  }
}
