import Swal from 'sweetalert2';
import { EquipamentoApontamentoService } from 'src/app/services/equipamento-apontamento.service';
import { OrdemServicoEquipamentoApontamento } from './../../../classes/OrdemServicoEquipamentoApontamento';
import { OrdemServico } from './../../../classes/OrdemServico';
import { AgendaAtividade } from './../../../classes/AgendaAtividade';
import { EquipamentoApontamento } from './../../../classes/EquipamentoApontamento';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { FuncionarioService } from 'src/app/services/funcionario.service';
import { Funcionario } from 'src/app/classes/Funcionario';
import { Equipamento } from 'src/app/classes/Equipamento';
import { EquipamentoService } from 'src/app/services/equipamento.service';
import { ImprodutividadeEquipamento } from 'src/app/classes/ImprodutividadeEquipamento';
import { TipoImprodutividade } from 'src/app/classes/TipoImprodutividade';
import { TipoImprodutividadeService } from 'src/app/services/tipo-improdutividade.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { AreaService } from 'src/app/services/area.service';
import { Area } from 'src/app/classes/Area';
import { UtilsService } from 'src/app/utils/utils.service';
import { ApontamentoFrotaService } from 'src/app/services/apontamento-frota.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-apontamento-maquina',
  templateUrl: './apontamento-maquina.component.html',
  styleUrls: ['./apontamento-maquina.component.scss']
})
export class ApontamentoMaquinaComponent implements OnInit {
  @Input() agendaAtividade: AgendaAtividade;
  @Input() ordensServico: OrdemServico[];
  @Input() inserindo: boolean;
  @Input() equipamentoApontamento: EquipamentoApontamento;
  @Input() apontamentoAvulso: boolean;
  @Output() fechou: EventEmitter<any> = new EventEmitter();

  @ViewChild('Modal', { static: false })
  Modal: UiModalComponent;

  funcionarios: Funcionario[];
  equipamentos: Equipamento[] = [];
  implementos: Equipamento[] = [];
  areas: Area[];

  improdutividadesEquipamento: ImprodutividadeEquipamento[] = [];
  tiposImprodutividade: TipoImprodutividade[];
  ordensServicoEquipamentoApontamento: OrdemServicoEquipamentoApontamento[];

  carregando: boolean;
  isSubmit: boolean = false;
  temImplemento: boolean = true;

  fazendaId: number = 0;

  // tradução
  ATENCAO: string = 'Atenção!';
  A_AREA_INFORMADA_PARA_A_OS_: string = 'A área informada para a OS #';
  _ULTRAPASSA_AREA_PREVISTA_MAXIMO_PERMITIDO_: string = ' ultrapassa área prevista. Máximo permitido: ';
  FEITO: string = 'Feito!';
  APONTAMENTO_ALTERADO: string = 'Apontamento alterado!';
  APONTAMENTO_CADASTRADO: string = 'Apontamento cadastrado!';
  formulario: FormGroup;

  constructor(private funcionarioService: FuncionarioService,
    private equipamentoService: EquipamentoService,
    private tipoImprodutividadeService: TipoImprodutividadeService,
    private equipamentoApontamentoService: EquipamentoApontamentoService,
    private toasty: CustomToastyService,
    private areaService: AreaService,
    private utils: UtilsService,
    private apontamentoFrotaService: ApontamentoFrotaService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.funcionarioService.getFuncionarios().subscribe(ret => {
      this.funcionarios = ret;
    },
      err => {
        this.utils.getErro(err);
      });

    this.equipamentoService.getEquipamentos().subscribe(ret => {
      this.equipamentos = ret;
      this.implementos = this.equipamentos.filter(x => x.FlagImplemento);
      this.equipamentos = this.equipamentos.filter(x => !x.FlagImplemento);
    });

    this.tipoImprodutividadeService.getTiposImprodutividade().subscribe(ret => {
      this.tiposImprodutividade = ret;
      for (let i = 0; i < this.tiposImprodutividade.length; i++) {
        if (this.tiposImprodutividade[i].Codigo > 0) {
          this.tiposImprodutividade[i].Descricao = this.tiposImprodutividade[i].Codigo + '-' + this.tiposImprodutividade[i].Descricao;
        }
      }
    });

    if (this.inserindo) {
      this.equipamentoApontamento = new EquipamentoApontamento();
      this.equipamentoApontamento.Data = new Date();
      if (this.agendaAtividade) {
        this.equipamentoApontamento.AgendaAtividadeId = this.agendaAtividade.Id;
        this.equipamentoApontamento.EquipamentoId = this.agendaAtividade.EquipamentoId;
        this.equipamentoApontamento.FuncionarioId = this.agendaAtividade.FuncionarioId;
      }
      if (this.ordensServico) {
        this.equipamentoApontamento.Area = 0;
        this.ordensServicoEquipamentoApontamento = [];
        this.ordensServico.forEach(x => {
          this.equipamentoApontamentoService.AreaApontadaPorOs(x.Id).subscribe(ret => {
            let osApontamento: OrdemServicoEquipamentoApontamento = new OrdemServicoEquipamentoApontamento();
            osApontamento.OrdemServicoId = x.Id;
            osApontamento.Area = x.AreaPrevista - ret;
            osApontamento.AreaPrevista = x.AreaPrevista;
            osApontamento.AreaApontada = ret;
            if (osApontamento.Area > 0) {
              this.equipamentoApontamento.Area += osApontamento.Area;
              this.ordensServicoEquipamentoApontamento.push(osApontamento);
            }
          })
        })
      }
    }
    else{
      this.improdutividadesEquipamento = this.equipamentoApontamento.ImprodutividadesEquipamento.filter(p => p.EquipamentoApontamentoId ==  this.equipamentoApontamento.Id);
      this.equipamentoApontamentoService.getOrdemServicoEquipamentoApontamento(this.equipamentoApontamento.Id).subscribe(ret => {
        this.ordensServicoEquipamentoApontamento = ret
      });
    }

    setTimeout(() => {
      this.Modal.show();
    }, 500)
  }

  fechar() {
    this.Modal.hide();
    this.fechou.emit();
  }

  salvar(form: any) {
    this.formulario = new FormGroup({
      fazenda: new FormControl(this.fazendaId, [Validators.required]),
      areaId: new FormControl(this.equipamentoApontamento.AreaId, this.apontamentoAvulso ? [Validators.required] : null),
      atividade: new FormControl(this.equipamentoApontamento.AtividadeId, this.apontamentoAvulso ? [Validators.required] : null),
      data: new FormControl(this.equipamentoApontamento.Data, [Validators.required]),
      funcionario: new FormControl(this.equipamentoApontamento.FuncionarioId, [Validators.required]),
      equipamento: new FormControl(this.equipamentoApontamento.EquipamentoId, [Validators.required]),
      implemento: new FormControl(this.equipamentoApontamento.ImplementoId),
      area: new FormControl(this.equipamentoApontamento.Area, [Validators.required]),
      horaInicio: new FormControl(this.equipamentoApontamento.HoraInicio, [Validators.required]),
      horaFim: new FormControl(this.equipamentoApontamento.HoraFim, [Validators.required]),
    })
    if (!this.formulario.valid) {
      this.isSubmit = true;
      return;
    }

    if(!this.validateImprodutividades()){
      Swal.fire(this.ATENCAO, 'Horas improdutivas com horário inconsistente', 'warning');
      return;
    }

    if(!this.equipamentoApontamento.HorimetroFinal){
      this.utils.handleWarning("Horímetro final deve ser preenchido!");
      return;
    }
    if(this.equipamentoApontamento.HorimetroFinal <= this.equipamentoApontamento.HorimetroInicial){
      this.utils.handleWarning("Horímetro final deve ser maior que horímetro inicial!");
      return;
    }


    this.configurarTraducao();
    if(this.equipamentoApontamento.HorimetroInicial > this.equipamentoApontamento.HorimetroFinal){
      Swal.fire(this.ATENCAO, 'Horímetro inicial não pode ser maior que o horímetro final', 'warning');
      return;
    }
    if(this.equipamentoApontamento.HorimetroInicial < 0 || this.equipamentoApontamento.HorimetroFinal <= 0){
      Swal.fire(this.ATENCAO, 'Horímetro de início e fim devem ser preenchidos', 'warning');
      return;
    }
    this.carregando = true;
    for (let i = 0; i < this.ordensServicoEquipamentoApontamento.length; i++) {
      const x = this.ordensServicoEquipamentoApontamento[i];
      if (x.Area + x.AreaApontada > x.AreaPrevista) {
        Swal.fire(this.ATENCAO, this.A_AREA_INFORMADA_PARA_A_OS_ + x.OrdemServicoId + this._ULTRAPASSA_AREA_PREVISTA_MAXIMO_PERMITIDO_ + (x.AreaPrevista - x.AreaApontada), 'warning');
        return;
      }
    }

    if (this.equipamentoApontamento.HoraInicio.length == 5)
      this.equipamentoApontamento.HoraInicio = this.equipamentoApontamento.HoraInicio + ":00";
    if (this.equipamentoApontamento.HoraFim.length == 5)
      this.equipamentoApontamento.HoraFim = this.equipamentoApontamento.HoraFim + ":00";
    this.improdutividadesEquipamento.forEach(i => {
      if(i.Novo)
        i.Id = 0;
      if (i.HoraInicio && i.HoraInicio.length <= 5)
        i.HoraInicio = i.HoraInicio + ":00";

      if (i.HoraFim && i.HoraFim.length <= 5)
        i.HoraFim = i.HoraFim + ":00";
    });
    if(this.inserindo)
      this.equipamentoApontamento.Id = 0;

    this.ordensServicoEquipamentoApontamento.forEach(x => { x.Id = 0; });
    this.equipamentoApontamentoService.SalvarCompleto(this.equipamentoApontamento,
      this.ordensServicoEquipamentoApontamento,
      this.improdutividadesEquipamento.filter(i => i.TipoImprodutividadeId)).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.equipamentoApontamento.Id ? this.APONTAMENTO_ALTERADO : this.APONTAMENTO_CADASTRADO, 'success');
          this.carregando = false;
          this.isSubmit = false;

          this.Modal.hide();
          this.fechou.emit();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  changeHorimetro() {
    this.equipamentoApontamento.HorasTrabalhadas = 0;
    this.equipamentoApontamento.Rendimento = 0;
    this.equipamentoApontamento.ImprodutividadeTotalTexto = '';
    if (this.equipamentoApontamento.HorimetroInicial &&
      this.equipamentoApontamento.HorimetroFinal) {
      if (!this.equipamentoApontamento.ImprodutividadeTotal)
        this.equipamentoApontamento.ImprodutividadeTotal = 0;

      this.equipamentoApontamento.HorasTrabalhadas = this.equipamentoApontamento.HorimetroFinal - this.equipamentoApontamento.HorimetroInicial - this.equipamentoApontamento.ImprodutividadeTotal;

      if (this.equipamentoApontamento.Area && this.equipamentoApontamento.HorasTrabalhadas > 0)
        this.equipamentoApontamento.Rendimento = this.equipamentoApontamento.Area / this.equipamentoApontamento.HorasTrabalhadas;

      this.equipamentoApontamento.ImprodutividadeTotalTexto = this.utils.formatarHorimetro(this.equipamentoApontamento.ImprodutividadeTotal);
    }
  }

  changeAreaOs() {
    this.equipamentoApontamento.Area = 0;
    this.ordensServicoEquipamentoApontamento.forEach(x => {
      this.equipamentoApontamento.Area += x.Area;
    })
  }

  //improdutividade
  novaImprodutividade() {
    let equipamentoSelecionado = this.equipamentoApontamento.EquipamentoId;

    if (!this.improdutividadesEquipamento)
      this.improdutividadesEquipamento = [];

    if (this.improdutividadesEquipamento.length == 0 || this.improdutividadesEquipamento[this.improdutividadesEquipamento.length - 1].TipoImprodutividadeId > 0) {
      let novo = new ImprodutividadeEquipamento();
      novo.EquipamentoApontamentoId = equipamentoSelecionado;
      novo.Novo = true;
      novo.Id = new Date().getTime() * -1;
      this.improdutividadesEquipamento.push(novo);
    }
  }

  changeImprodutividade(improdutividade: ImprodutividadeEquipamento) {
    this.calcTempoParado();

    if (improdutividade.TipoImprodutividadeId > 0 &&
      ((improdutividade.HoraInicio &&
        improdutividade.HoraFim) ||
        (improdutividade.HorimetroInicial &&
          improdutividade.HorimetroFinal))) {
    }
  }

  toData(stringHora){
    var partesHora = stringHora.split(':');
    var data = new Date();
    data.setHours(parseInt(partesHora[0], 10));
    data.setMinutes(parseInt(partesHora[1], 10));
    return data;
  }

  validateImprodutividades(): boolean {
    let isValid = true;
    for(let improdutividade of this.improdutividadesEquipamento){
      if(!improdutividade.HoraInicio && improdutividade.HoraFim){
        this.utils.handleWarning("Horário Inconsistente!");
          isValid = false;
      }
      else if(improdutividade.HoraInicio && !improdutividade.HoraFim){
        this.utils.handleWarning("Horário Inconsistente!");
          isValid = false;
      }
      else if(improdutividade.HoraInicio && improdutividade.HoraFim) {
        const HoraInicio = this.toData(improdutividade.HoraInicio);
        const HoraFim = this.toData(improdutividade.HoraFim);
        const zero = this.toData("00:00")
        if(HoraInicio < zero || HoraFim <= zero){
          this.utils.handleWarning("Horário Inconsistente!");
          isValid = false;
        }
      }
    }
    return isValid;
  }

  timoutImprodutividade: any;
  changeHoraImprodutividade(improdutividade: ImprodutividadeEquipamento) {
    if(improdutividade.HoraInicio && improdutividade.HoraFim) {
      const HoraInicio = this.toData(improdutividade.HoraInicio);
      const HoraFim = this.toData(improdutividade.HoraFim);
      const zero = this.toData("00:00")
      if(HoraInicio < zero || HoraFim <= zero){
        this.utils.handleWarning("Horário Inconsistente!");
        return;
      }
    }

    this.calcTempoParado();

    if (this.timoutImprodutividade)
      clearTimeout(this.timoutImprodutividade);

    this.timoutImprodutividade = setTimeout(() => {
      this.changeImprodutividade(improdutividade);
    }, 500);
  }

  calcTempoParado() {
    this.equipamentoApontamento.ImprodutividadeTotal = 0;

    this.improdutividadesEquipamento.filter(i => i.TipoImprodutividadeId).forEach(i => {
      if (i.HorimetroInicial > 0 && i.HorimetroFinal > 0)
        this.equipamentoApontamento.ImprodutividadeTotal += i.HorimetroFinal - i.HorimetroInicial;
      else if (i.HoraFim && i.HoraInicio) {
        let horaInicio = new Date("1900-01-01T" + i.HoraInicio);
        let horaFim = new Date("1900-01-01T" + i.HoraFim);

        this.equipamentoApontamento.ImprodutividadeTotal += this.utils.calcularDiferencaHoras(horaInicio, horaFim).valor;
      }
    });

    this.changeHorimetro();
  }

  deleteImprodutividade(index: number) {
    let improdutividade = this.improdutividadesEquipamento[index];

    if (improdutividade.Id < 0)
      this.improdutividadesEquipamento.splice(index, 1);
    else
      improdutividade.FlagExcluido = true;

  }

  selecionaFazenda(fazendaId: number) {
    this.fazendaId = fazendaId;
    if (fazendaId > 0) {
      this.areaService.getAreasPorFazenda(fazendaId).subscribe(ret => {
        this.areas = ret;
      });
    }
    else
      this.areas = [];
  }

  selecionaArea(areaId: number) {
    this.equipamentoApontamento.AreaId = areaId;
    this.areaService.getArea(areaId).subscribe(ret => {
      let areaLocal: Area = ret;
      this.equipamentoApontamento.Area = areaLocal.AreaTotal;
    })
  }

  selecionaAtividade(atividadeId: number) {
    this.equipamentoApontamento.AtividadeId = atividadeId;
  }

  selecionarEquipamento(equipamentoId: number) {
    let equipamento = this.equipamentos.find(e => e.Id == equipamentoId);
    this.temImplemento = equipamento.UsaImplemento;
    this.equipamentoApontamentoService.getHorimetroFinal(equipamentoId).subscribe(ret => {
      this.equipamentoApontamento.HorimetroInicial = ret;
    });
  }

  configurarTraducao() {
    this.ATENCAO = this.translate.instant('ATENCAO');
    this.A_AREA_INFORMADA_PARA_A_OS_ = this.translate.instant('A_AREA_INFORMADA_PARA_A_OS_');
    this._ULTRAPASSA_AREA_PREVISTA_MAXIMO_PERMITIDO_ = this.translate.instant('_ULTRAPASSA_AREA_PREVISTA_MAXIMO_PERMITIDO_');
    this.FEITO = this.translate.instant('FEITO');
    this.APONTAMENTO_ALTERADO = this.translate.instant('APONTAMENTO_ALTERADO');
    this.APONTAMENTO_CADASTRADO = this.translate.instant('APONTAMENTO_CADASTRADO');
  }
}
