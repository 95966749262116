import { Injectable } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomToastyService } from '../theme/shared/components/toasty/custom-toasty.service';
import * as moment from 'moment'
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';
import { enumMoeda } from '../classes/Empresa';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private router: Router,
    private toasty: CustomToastyService,
    private translateService: TranslateService,
    private storageService: StorageService
  ) { }

  getErro(erro: any) {
    console.log("ERRO RETORNO=> ", erro);
    if(erro) {
      if(erro.status === 200)
        return;
      if (erro.status === 401) {
        //Swal.fire('Ops!', 'Você não tem acesso ou sua sessão expirou. Entre novamente!', 'warning');
        this.router.navigate(['/login']);
      }
      else if (erro.status === 400) {
        console.log("ERRO RETORNO=> ", erro);
        if (!isNullOrUndefined(erro.error)) {
          if(!isNullOrUndefined(erro.error.message)){
          Swal.fire('Erro!', erro.error.message, 'error');
          }
          else if(erro.error.errors){
            let msg = "Erros de validação de objeto <br />";;
            //vai ler o objeto de erro de validação
            for (let propertyName in erro.error.errors) {
              if (erro.error.errors.hasOwnProperty(propertyName)) {
                let values = erro.error.errors[propertyName];
                for (let value of values) {
                  msg += propertyName + ":" + value + "<br />";
                  console.log(propertyName, value);
                }
              }
            }
            Swal.fire('Erro!', msg, 'error');
          }
          else{
            Swal.fire('Erro!', erro.error, 'error');
          }
        }
      }
      else if (erro.status === 404) {
        console.log("ERRO RETORNO=> ", erro);
        if (!isNullOrUndefined(erro.error)) {
          Swal.fire('Erro!', isNullOrUndefined(erro.error.message) ? "Não Encontrado" : erro.error.message, 'error');
        }
      }
      else if (erro.status === 500) {
        console.log("ERRO RETORNO=> ", erro);
        if (!isNullOrUndefined(erro.error)) {
          if(!isNullOrUndefined(erro.error.message)){
          Swal.fire('Erro!', erro.error.message, 'error');
          }
          else{
            Swal.fire('Erro!', erro.error, 'error');
          }
        }
      }
      else {
        if (erro.error) {
          Swal.fire('Erro!', erro.error, 'error');
        } else {
          Swal.fire('Erro!', erro.message, 'error');
        }
      }
    }
  }
  swalError(msg){
    Swal.fire('Erro!', msg, 'error');
  }
  getConfirma(titulo: string, texto: string): boolean {
    Swal.fire({
      title: titulo,
      text: texto,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        return false;
      } else {
        return true;
      }
    });
    return false;
  }

  getDataForm(data: Date): NgbDateStruct {
    return {
      year: Number(data.toString().substr(0, 4)),
      month: Number(data.toString().substr(5, 2)),
      day: Number(data.toString().substr(8, 2))
    };
  }

  getData(data: NgbDateStruct): Date {
    return new Date(data.year, data.month - 1, data.day);
  }

  getDataHora(data: NgbDateStruct, hora: number, minuto: number): Date {
    return new Date(Date.UTC(data.year, data.month - 1, data.day, hora, minuto));
  }

  getDiasEntreDatas(dataInicio: Date, dataFim: Date): number {
    dataInicio = new Date(dataInicio.toString());
    dataFim = new Date(dataFim.toString());
    return (dataFim.getTime() - dataInicio.getTime()) / (1000 * 60 * 60 * 24);
  }

  handleSuccess(mensagem: string) {
    this.toasty.show(this.translateService.instant("FEITO"), mensagem, 'success');
  }

  handleWarning(mensagem: string) {
    this.toasty.show(this.translateService.instant('ATENCAO'), mensagem, 'warning');
  }

  handleInfo(mensagem: string) {
    this.toasty.show(this.translateService.instant('ATENCAO'), mensagem, 'info');
  }

  handleErro(mensagem: string) {
    Swal.fire(this.translateService.instant("ERRO"), mensagem, 'error');
  }

  handleError(mensagem: string) {
    this.handleErro(mensagem);
  }

  handleWarningSwal(titulo: string, mensagem: string) {
    Swal.fire(titulo, mensagem, 'warning');
  }

  formatarNumeros(numero: number) {
    let formatado = '0,0';
    try {
      formatado = numero.toString().replace('.', ';').replace(',', '.').replace(';', ',');
    } catch (Error) {

    }
    return formatado;
  }

  formatarDecimais(value) {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  calcularTempoDecorrido(data: Date): string {
    const { ano, mes, semana, dia, hora, minuto, segundo, atras, atualizadoAgora, meses } = this.traducaoTempoDecorrido();
    const diferenca = moment.duration(moment().diff(moment(data)));
    const intervalos = [[ano, diferenca.asYears()], [(diferenca.asMonths() >= 2 ? meses : mes), diferenca.asMonths()], [semana, diferenca.asWeeks()], [dia, diferenca.asDays()], [hora, diferenca.asHours()], [minuto, diferenca.asMinutes()], [segundo, diferenca.asSeconds()]];
    for (const [unidade, valor] of intervalos) {
      if (valor >= 1) {
        return `${Math.floor(parseFloat(valor.toString()))} ${unidade}${valor >= 2 && unidade != mes? 's' : ''} ${atras}`;
      }
    }
    return `0 ${segundo} ${atras}`;
  }

  traducaoTempoDecorrido() {
    let ano: string = this.translateService.instant("ANO");
    let mes: string = this.translateService.instant("MES");
    let semana: string = this.translateService.instant("SEMANA");
    let dia: string = this.translateService.instant("DIA");
    let hora: string = this.translateService.instant("HORA");
    let minuto: string = this.translateService.instant("MINUTO");
    let segundo: string = this.translateService.instant("SEGUNDO");
    let atras: string = this.translateService.instant("ATRAS");
    let atualizadoAgora: string = this.translateService.instant("ATUALIZADO_AGORA");
    let meses: string = this.translateService.instant("MESES");

    return { ano, mes, semana, dia, hora, minuto, segundo, atras, atualizadoAgora, meses }
  }

  calcularDiferencaHoras(dataInicio: Date, dataFim: Date): any {
    // Calcule a diferença em milissegundos
    const diferencaEmMilissegundos = dataFim.getTime() - dataInicio.getTime();

    // Converta a diferença para horas, minutos e segundos
    const segundos = Math.floor(diferencaEmMilissegundos / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);

    // Calcule os minutos e segundos restantes
    const minutosRestantes = minutos % 60;
    const segundosRestantes = segundos % 60;

    //Cria a diferença em decimais
    let decimal  = minutosRestantes / 60;
    decimal = horas + decimal;

    return {horas: horas, minutos: minutosRestantes, valor: decimal};
  }

  formatarHorimetro(HorasDecimal: number): string {
    let horas = Math.trunc(HorasDecimal);
    let minutos = Math.trunc((HorasDecimal - horas) * 60);
    return horas.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0');
  }

  formatarData(data: string): string {
    return moment(data).format('DD/MM/YYYY');
  }
  formatarDataHora(data: string): string {
    return moment(data).format('DD/MM/YYYY HH:mm');
  }

  formatarDatas(data: Date): string {
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const dia = String(data.getDate()).padStart(2, '0');
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
    const segundos = String(data.getSeconds()).padStart(2, '0');
    const milissegundos = String(data.getMilliseconds()).padStart(3, '0');

    return `${ano}-${mes}-${dia}T${horas}:${minutos}:${segundos}.${milissegundos}`;
  }

  public toQueryString(obj: any): string {
    const parts = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        if (obj[key] instanceof Date) {
          const dateStr = this.formatarDatas(obj[key]);
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(dateStr)}`);
        } else {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
      }
    }
    return parts.join('&');
  }

  public isValidGuid(input: string): boolean {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return guidRegex.test(input);
  }


  getCifraMoeda(): string {
    let moeda = this.storageService.getEmpresa().Moeda
    let cifra = ''
    switch (moeda.toLowerCase()) {
      case 'real':
        cifra = 'R$';
        break;
      case 'dólar':
        cifra = 'US$';
        break;
      default:
        break;
    }
    return cifra;
  }
}
