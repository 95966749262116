import { Usuario } from 'src/app/classes/Usuario';
export class Comentario{
    Id: number;
    ChamadoId: number;
    Autor: string;
    Descricao: string;
    DataAlteracao: string;
    DataCadastro: string;
    UsuarioAlteracaoId: number;
    UsuarioCadastroId: number;

    // Nao mapeados
    UsuarioCadastro: Usuario = new Usuario();
    Excluindo: boolean = false;
    IsEditar = false;
    CarregandoSave = false;
}
