import { UtilsService } from 'src/app/utils/utils.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EnumCriticidadeChamado, EnumModuloChamado, EnumStatusChamado, EnumTipoChamado, ListaCriticidadeChamado, ListaModuloChamado, ListaStatusChamado, ListaTipoChamado } from 'src/app/classes/chamado/Chamado';
import { Empresa } from 'src/app/classes/Empresa';
import { Usuario } from 'src/app/classes/Usuario';
import { EmpresaService } from 'src/app/services/empresa.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-filtro-chamado',
  templateUrl: './filtro-chamado.component.html',
  styleUrls: ['./filtro-chamado.component.scss']
})
export class FiltroChamadoComponent implements OnInit {

  constructor(
    private empresaService: EmpresaService,
    private storageService: StorageService,
    private utils: UtilsService
  ) { }

  @Output() filtrar = new EventEmitter<any>();

  public usuario: Usuario;

  public listaModulos = ListaModuloChamado;
  public listaCriticidades = ListaCriticidadeChamado;
  public listaTipos = ListaTipoChamado;
  public listaStatus = ListaStatusChamado;
  public listaEmpresas: Empresa[];

  public modulo: EnumModuloChamado;
  public tipo: EnumTipoChamado;
  public status: EnumStatusChamado;
  public criticidade: EnumCriticidadeChamado;
  public dataInicio: Date;
  public dataFim: Date;
  public empresa: number = 0;

  ngOnInit() {
    this.dataInicio = new Date();
    this.dataInicio.setHours(0, 0, 0, 0);
    this.dataFim = new Date();
    this.dataFim.setHours(23, 59, 0, 0);
    this.modulo = null;
    this.tipo = null;
    this.status = null;
    this.criticidade = null;
    this.empresa = null;

    this.usuario = this.storageService.getUsuario();
    if(this.usuario.isSuper){
      this.empresaService.getEmpresas()
      .subscribe(
        res => {
          this.listaEmpresas = res;
        },
        err => {
          this.utils.getErro(err);
        }
      )
    }
  }

  filter(){
    this.filtrar.emit({
      modulo: null,
      tipo: this.tipo,
      status: this.status,
      criticidade: this.criticidade,
      dataInicio: this.dataInicio.toISOString(),
      dataFim: this.dataFim.toISOString(),
      empresa: this.empresa})
  }

}
