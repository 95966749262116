import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { EnumStatusOS, OrdemServico } from 'src/app/classes/OrdemServico';
import { environment } from 'src/environments/environment';
import { ItemOrdemServico } from '../classes/ItemOrdemServico';
import { UtilsService } from '../utils/utils.service';
import { ItemOrdemServicoService } from './item-ordem-servico.service';
import { TipoAplicacaoService } from './tipo-aplicacao.service';
import { TipoAplicacao } from 'src/app/classes/TipoAplicacao';
import { AtividadeService } from 'src/app/services/atividade.service';

@Injectable({
  providedIn: 'root'
})
export class OrdemServicoService {

  private osCriadaEmiter = new Subject<any>();
  emitirEventoOsSalva(data: any) {
    this.osCriadaEmiter.next(data);
  }
  osSalvaEvento() {
    return this.osCriadaEmiter.asObservable();
  }

  private osLiberadaEmiter = new Subject<any>();
  private emitirEventoOsLiberada(data: any) {
    this.osLiberadaEmiter.next(data);
  }
  osLiberadaEvento() {
    return this.osLiberadaEmiter.asObservable();
  }

  private areaChangeEmiter = new Subject<boolean>();
  emitirEventoAreaChange(data: boolean) {
    this.areaChangeEmiter.next(data);
  }
  areaChangeEvento() {
    return this.areaChangeEmiter.asObservable();
  }
  private osMultiplaCriadaEmiter = new Subject<any>();
  emitirEventoOsMultiplaCriada(data: any) {
    this.osMultiplaCriadaEmiter.next(data);
  }
  osMultiplaCriadaEvento() {
    return this.osMultiplaCriadaEmiter.asObservable();
  }

  private changeProdutoEmiter = new Subject<any>();
  emitirEventochangeProduto() {
    this.changeProdutoEmiter.next(true);
  }
  changeProdutoEvento() {
    return this.changeProdutoEmiter.asObservable();
  }

  private erroEmiter = new Subject<any>();
  emitirEventoErro(err: any) {
    this.erroEmiter.next(err);
  }
  erroEvento() {
    return this.erroEmiter.asObservable();
  }

  private listarItensEvento = new Subject<any>();
  emitirEventoListarItens() {
    this.listarItensEvento.next(true);
  }
  listagemItensEvento() {
    return this.listarItensEvento.asObservable();
  }

  private osConcluidaEvento = new Subject<any>();
  emitirEventoOsConcluida() {
    this.osConcluidaEvento.next(true);
  }
  OsConcluidaEvento() {
    return this.osConcluidaEvento.asObservable();
  }

  constructor(
    private translate: TranslateService,
    private http: HttpClient,
    private tipoAplicacaoService: TipoAplicacaoService,
    private atividadeService: AtividadeService,
    private utils: UtilsService,
    private itemOrdemServicoService: ItemOrdemServicoService) { }

  getOrdensServico(): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico`)
  }

  getOrdemServico(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/${id}`)
  }

  postOrdemServico(ordemServico: OrdemServico): Observable<any> {
    return this.http.post(`${environment.apiURL}/ordemServico`, ordemServico);
  }

  putOrdemServico(id: number, ordemServico: OrdemServico): Observable<any> {
    return this.http.put(`${environment.apiURL}/ordemServico/${id}`, ordemServico);
  }

  atualizarDtPrevista(id: number, dtPrevista: string): Observable<any>{
    return this.http.put(`${environment.apiURL}/ordemServico/atualizarDtPrevista/${id}?dtPrevista=${dtPrevista}`, null);
  }

  deleteOrdemServico(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/ordemServico/${id}`);
  }

  deleteOrdemServicoPorSafra(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/ordemServico/DeletePorSafra/${id}`);
  }

  getOrdensServicoPorPlanejamento(planejamentoId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/listar/${planejamentoId}`)
  }

  gerarOrdemServico(empresaId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/gerarOS/${empresaId}`);
  }

  gerarOrdemServicoPorSafra(safraId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/gerarOsSafra/${safraId}`);
  }

  gerarOrdemServicoPorArea(areaId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/gerarOsArea/${areaId}`);
  }

  detalhamento(filtros: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/ordemServico/detalhamento`, filtros);
  }

  confirmar(ids: number[]): Observable<any> {
    return this.http.post(`${environment.apiURL}/ordemServico/confirmar`, ids);
  }

  atribuir(ids: number[], funcionarioId: number): Observable<any> {
    return this.http.post(`${environment.apiURL}/ordemServico/atribuir/${funcionarioId}`, ids);
  }

  count(status: number, periodo: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/count/${status}/${periodo}`)
  }

  reabrirOS(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/Reabrir/${id}`)
  }
  reativar(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/Reativar/${id}`)
  }
  reabrirFechada(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/ReabrirFechada/${id}`)
  }
  cancelarOs(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/Cancelar/${id}`)
  }
  duplicarOs(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/Duplicar/${id}`)
  }

  imprimir(id: number) {
    return this.http.get(`${environment.apiURL}/ordemServico/gerarPDF/${id}`,  { responseType: 'blob' })
    .subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    }, err => {
      this.utils.getErro(err);
    });
  }

  getStatusOS(os: OrdemServico): string {
    switch (os.Status) {
      case EnumStatusOS.Aberta: return this.translate.instant('ABERTA');
      case EnumStatusOS.AguardandoLiberacao: return  this.translate.instant('AGUARDANDO_LIBERACAO');
      case EnumStatusOS.Pendente: return this.translate.instant('PENDENTE');
      case EnumStatusOS.ParcialmenteConcluida: return this.translate.instant('PARCIALMENTE_CONCLUIDA');
      case EnumStatusOS.Concluida: return this.translate.instant('CONCLUIDA');
      case EnumStatusOS.Cancelada: return this.translate.instant('CANCELADA');
      default: return "";
    }
  }

  custo(filtros: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/ordemServico/custo`, filtros);
  }

  custoPorSafra(fazendaId: number, areaId: number, safraId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/ordemServico/custoSafra/${fazendaId}/${areaId}/${safraId}`)
  }

  salvarMultiplasOSAvulsas(os: OrdemServico, itens: ItemOrdemServico[]): Observable<any> {
    return this.http.post(`${environment.apiURL}/ordemServico/SalvarMultiplasOSAvulsas`,
      {
        OrdemServico: os,
        Itens: itens,
        Areas: os.AreasMultiplas
      }
    )
  }

  confirmarLiberacaoInsumos(os: OrdemServico) {

    this.atividadeService.getAtividade(os.AtividadeId).subscribe(
      res => {
        if(res.UtilizaInsumo && !os.TipoAplicacaoId){
          this.utils.handleErro("Selecione um Tipo de Aplicação!");
          return;
        }
      },
      err => {
        this.utils.handleErro(err)
        return;
      }
    )

    if(!os.TipoAplicacaoId) {
      this.save(os, [], true)
      return;
    }

    os.Vazao = os.Vazao ? os.Vazao : 0;
    os.VolumeAgua = os.VolumeAgua ? os.VolumeAgua : 0;

    this.tipoAplicacaoService.getTipoAplicacao(os.TipoAplicacaoId).subscribe(
        tipoAplicacao => {

          const erros = this.validarLiberacao(os, tipoAplicacao);
          if(erros.length > 0) {
            this.utils.handleErro(erros.join('<br>'))
            return;
          }
          this.emitirEventoOsSalva(os);
          os.Status = EnumStatusOS.Pendente;
          this.putOrdemServico(os.Id, os).subscribe(
              res => {
                this.emitirEventoOsLiberada(res);
              },
              err => {
                this.utils.getErro(err);
              }
          );
        },
        err => this.utils.getErro(err)
    );
  }

  save(os: OrdemServico, itens: ItemOrdemServico[] = [], liberar = false) {

    os.Vazao = os.Vazao ? os.Vazao : 0;
    os.VolumeAgua = os.VolumeAgua ? os.VolumeAgua : 0;
    os.AreaPorTanque = os.AreaPorTanque ? os.AreaPorTanque : 0;
    os.AreaPorTanque = os.AreaPorTanque == Infinity ? 0 : os.AreaPorTanque;
    os.Status = os.Status == EnumStatusOS.Aberta ? EnumStatusOS.AguardandoLiberacao : os.Status;

    if(os.AreasMultiplas.length == 0 && !this.validarOrdemServico(os)){
      this.utils.handleWarning("Dados Inválidos!");
      return;
    }

    if(liberar) {
      if(!this.validarOSServicos(os))
        return;
      os.Status = 3; // pendente
    }

    setTimeout(() => {
      if (os.AreasMultiplas.length > 0) {
        os.AreaId = 1;
        itens.forEach((i,j)=>{
          itens[j].Id = 0;
        })
        this.salvarMultiplasOSAvulsas(os, itens).subscribe(
          res => {
            this.emitirEventoOsMultiplaCriada(res)
          },
          err => this.utils.getErro(err)
        );
      }
      else
      {
        if (os.Id > 0) {
          this.salvarOrdemExistente(os);
        } else {
          this.salvarNovaOrdem(os);
        }
      }
    }, 300);
  }

  private validarOSServicos(os: OrdemServico): boolean {

    const requiredFields = [
      { condition: true, field: os.EquipamentoId, message: 'Selecione um Equipamento!' },
      { condition: true, field: os.FuncionarioLiberacaoId, message: 'Selecione um Operador!' },
      { condition: true, field: os.AreaPorTanque, message: 'Informe a área por maior que zero!' },
    ];

    const erros = requiredFields.reduce((errors, { condition, field, message }) => {
      if (condition && !field) {
        errors.push(message);
      }
      return errors;
    }, []);

    if(erros.length > 0) {
      this.utils.handleErro(erros.join('<br>'))
      return false;
    }

    return true;
  }

  private salvarOrdemExistente(os: OrdemServico) {
    this.putOrdemServico(os.Id, os).subscribe(
      res => {
        os = res;
        this.utils.handleSuccess(this.translate.instant('ORDEM_DE_SERVICO_ALTERADA'));
        this.emitirEventoOsSalva(os);
      },
      err => {
        this.utils.getErro(err);
      }
    );
  }

  private salvarNovaOrdem(os: OrdemServico) {
    os.Status = EnumStatusOS.AguardandoLiberacao;
    this.postOrdemServico(os).subscribe(
      res => {
        this.utils.handleSuccess(this.translate.instant('ORDEM_DE_SERVICO_CADASTRADA'));
        os = res;
        this.emitirEventoOsSalva(os);
      },
      err => {
        this.utils.getErro(err);
    });
  }

  concluirOS(os: OrdemServico) {

    os.Vazao = os.Vazao ? os.Vazao : 0;
    os.VolumeAgua = os.VolumeAgua ? os.VolumeAgua : 0;

    if(!this.validarOSServicos(os))
      return;

    this.emitirEventoOsSalva(os);

    let status = os.Status;
    os.Status = EnumStatusOS.Concluida;
    os.DataRealizada = os.DataRealizada == null ? new Date() : os.DataRealizada;
    os.DataRealizadaFinal = os.DataRealizadaFinal == null ? new Date() : os.DataRealizadaFinal;
    this.putOrdemServico(os.Id, os).subscribe(
      (res: OrdemServico) => {
        os = res;
        this.utils.handleSuccess(res.Status == EnumStatusOS.Concluida ?
          this.translate.instant('ORDEM_DE_SERVICO_CONCLUIDA') :
          this.translate.instant('ORDEM_DE_SERVICO_PARCIALMENTE_CONCLUIDA')
        );
        this.emitirEventoOsConcluida();
      },
      err => {
        this.utils.getErro(err);
        os.Status = status;
        this.emitirEventoErro(err);
      }
    );
  }

  private validarOrdemServico(os: OrdemServico): boolean {
    const formulario = new FormGroup({
      fazendaId: new FormControl(os.FazendaId, [Validators.min(1), Validators.required]),
      areaId: new FormControl(os.AreaId, [Validators.min(1), Validators.required]),
      dataPrevista: new FormControl(os.DataPrevista, [Validators.required]),
      dataPrevistaFinal: new FormControl(os.DataPrevistaFinal, [Validators.required]),
      safraId: new FormControl(os.SafraId, [Validators.min(1), Validators.required]),
      culturaId: new FormControl(os.CulturaId, [Validators.min(1), Validators.required]),
      areaPrevista: new FormControl(os.AreaPrevista, [Validators.min(1), Validators.required]),
      processoPlanejamentoId: new FormControl(os.ProcessoPlanejamentoId, [Validators.min(1), Validators.required]),
      atividadeId: new FormControl(os.AtividadeId, [Validators.min(1), Validators.required]),
      descricaoEtapa: new FormControl(os.DescricaoEtapa, [Validators.required]),
    })
    if (!formulario.valid) {
      return false;
    }
    return true;
  }

  private validarLiberacao(os: OrdemServico, tipoAplicacao: TipoAplicacao): Array<string> {
    const requiredFields = [
      { condition: true, field: os.EquipamentoId, message: 'Selecione um Equipamento!' },
      { condition: true, field: os.FuncionarioLiberacaoId, message: 'Selecione um Operador!' },
      { condition: tipoAplicacao.ExigeCapacidadeTanque, field: os.AreaPorTanque, message: 'Informe a área por tanque maior que zero!' },
      { condition: tipoAplicacao.ExigeVazao, field: os.Vazao, message: 'Vazão Inválida!' },
      { condition: tipoAplicacao.ExigeTipoBico, field: os.TipoBicoId, message: 'Tipo Bico Inválido!' },
      { condition: tipoAplicacao.ExigeCapacidadeTanque, field: os.VolumeAgua, message: 'Volume Água Inválido!' },
      //{ condition: tipoAplicacao.ExigeTaxa, field: os.TaxaFixa, message: 'Volume Água Inválido!' },
      { condition: tipoAplicacao.ExigePercentualIrrigacao, field: os.PercentualIrrigacao, message: 'Percentual Irrigação Inválido!' },
      { condition: tipoAplicacao.ExigePesoBalde, field: os.PesoBalde, message: 'Peso Balde Inválido!' },
      { condition: tipoAplicacao.ExigeUmidade, field: os.Umidade, message: 'Umidade Inválida!' },
      { condition: tipoAplicacao.ExigeVelocidadeVento, field: os.VelocidadeVento, message: 'Velocidade do Vento Inválida!' },
      { condition: tipoAplicacao.ExigePhCalda, field: os.PhCalda, message: 'PH Calda Inválida!' },
      { condition: tipoAplicacao.ExigeTemperatura, field: os.Temperatura, message: 'Temperatura Inválida!' }
    ];

    const erros = requiredFields.reduce((errors, { condition, field, message }) => {
      if (condition && !field) {
        errors.push(message);
      }
      return errors;
    }, []);

    return erros;
  }
}
